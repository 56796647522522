import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { firebaseAuth } from '../../firebase';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ILoginUserData } from '../auth/type';
import { StudentUserData } from './type';
import { getUserByEmail } from '../../database/datahelper';

export const studentLogin = createAsyncThunk(
  'studentAuth/login',
  async (loginData: ILoginUserData, { rejectWithValue }) => {
    try {
      const result: any = await signInWithEmailAndPassword(
        firebaseAuth,
        loginData.email,
        loginData.password,
      );

      const { idToken } = result._tokenResponse;
      const userInfo = await getUserByEmail(loginData.email);

      return {
        userInfo: userInfo,
        expiryToke: result.user.stsTokenManager.expirationTime,
        refreshToken: result.user.stsTokenManager.refreshToken,
        token: idToken,
      } as StudentUserData;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        toast.error(error.message);
        return rejectWithValue(error.message);
      }
    }
  },
);

export const studentLogout = createAsyncThunk(
  'studentAuth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await signOut(firebaseAuth);
      return;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
