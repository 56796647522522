import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Paper,
} from '@mui/material';

import { HeadingText, Text } from '../../../common/text/TextStyle';
import { QuestionTypeOptions } from '../../../constant/OptConstant';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { AskDoubtQuestionListType } from '../type';

type AskDoubtQuestionCardProps = {
  questionInfo: AskDoubtQuestionListType;
};
const AskDoubtQuestionCard = ({ questionInfo }: AskDoubtQuestionCardProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper
          elevation={2}
          sx={{
            padding: '15px 30px',
            borderRadius: '10px',
          }}
        >
          <Accordion
            sx={{
              boxShadow: 'none',
              padding: 0,
            }}
          >
            <AccordionSummary
              expandIcon={<MdKeyboardArrowUp style={{ fontSize: 30 }} />}
              sx={{
                padding: 0,
              }}
            >
              <Box
                flexWrap={'wrap'}
                alignItems={'center'}
                display={'flex'}
                width={'100%'}
                justifyContent={'space-between'}
              >
                <Box>
                  <HeadingText textheight='32px' size='xs' textweight='bold'>
                    {questionInfo.title}
                  </HeadingText>
                </Box>
                <Box alignItems={'center'} gap={'5px'} display={'flex'} flexWrap={'wrap'}>
                  <Chip
                    label={
                      QuestionTypeOptions.find((que) => que.value === questionInfo.questionCategory)
                        ?.label
                    }
                  />
                  {questionInfo.subjectTags.map((tag) => (
                    <Chip
                      label={QuestionTypeOptions.find((que) => que.value === tag)?.label}
                      key={tag}
                    />
                  ))}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 0,
              }}
            >
              <Box>
                <div
                  dangerouslySetInnerHTML={{
                    __html: questionInfo.body,
                  }}
                />
              </Box>

              <Text mt={'30px'} size='md' textweight='medium'>
                Answer
              </Text>
              <Box>
                {questionInfo.answer ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: questionInfo.answer,
                    }}
                  />
                ) : (
                  <Text mt={'20px'} size='md' textcolor='grey'>
                    Waiting for answer from Doubt JEE
                  </Text>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AskDoubtQuestionCard;
