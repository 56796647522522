import { Box } from '@mui/material';
import Styled from '@emotion/styled';

import { _url } from '../../../util/SiteUtil';
import { ColorTheme } from '../../../theme/color';

type HomeBackgroundImageContainerProps = {
  bgimage: string;
};
export const HomeBackgroundImageContainer = Styled(Box)<HomeBackgroundImageContainerProps>`
    background-image: ${(props) => `url(${_url(props.bgimage)})`} ;
    background-size: cover;
    min-height: 610px;
    @media (max-width: 900px) {
        background-image: ${(props) => `url(${_url(props.bgimage)})`} ;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: ${ColorTheme.base.white};
    }
`;

export const CsCard = Styled(Box)`
    background: url(https://www.sarthaks.com/qa-theme/SnowFlat/images/parallax.png) right bottom no-repeat, linear-gradient(269.74deg, var(--blue) .21%, #b4a1ff 99.75%);
    background-size: 500%;
    position: absolute;
    border-radius: 70px 0px 0.5rem;
    bottom: 0;
    right: 0;
    height: 4.5rem;
    width: 5rem;
    text-decoration: none;
    z-index: 0;
`;
