import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const initialState = {
  showLoginModal: false,
};

const appSlice = createSlice({
  name: 'APP_CONSTANTS',
  initialState,
  reducers: {
    updateShowLoginModalStatus(state, status: PayloadAction<boolean>) {
      const stateClone = cloneDeep(state);
      stateClone.showLoginModal = status.payload;
      return stateClone;
    },
  },
});

export const { updateShowLoginModalStatus } = appSlice.actions;
export default appSlice.reducer;
