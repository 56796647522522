import { Grid } from '@mui/material';
import Lottie from 'lottie-react';
import { ReactNode } from 'react';
import loadingIcon from '../../assets/loading.json';

type LoadingWrapperProps = {
  children: ReactNode;
  loading: boolean;
  height?: string;
};

const LoadingWrapper = ({ children, loading, height }: LoadingWrapperProps) => {
  if (loading) {
    return (
      <Grid container justifyContent={'center'} alignItems={'center'} height={height || '80vh'}>
        <Lottie
          style={{
            height: 200,
            width: 200,
          }}
          animationData={loadingIcon}
          loop={true}
        />
        ;
      </Grid>
    );
  }
  return <>{children}</>;
};

export default LoadingWrapper;
