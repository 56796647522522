import { Grid } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { BACKGROUND_COLOR } from "../../theme/color";
import { useNavigate } from "react-router";
import Sidebar from "./component/Sidebar";
import Header from "./component/Header";

type SidenavbarProps = {
  children: ReactNode;
};
const Sidenavbar: FC<SidenavbarProps> = ({ children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <Grid height={"100vh"} p={"10px"} bgcolor={BACKGROUND_COLOR} container>
      <Grid item width={isSideBarOpen ? "240px" : "80px"}>
        <Sidebar
          navigate={(to) => navigate(to)}
          setIsSideBarOpen={(isOpen) => setIsSideBarOpen(isOpen)}
          isSideBarOpen={isSideBarOpen}
        />
      </Grid>
      <Grid
        px={"20px"}
        item
        width={isSideBarOpen ? "calc(100% - 240px)" : "calc(100% - 80px)"}
      >
        <Header isSideBarOpen={isSideBarOpen}>{children}</Header>
      </Grid>
    </Grid>
  );
};

export default Sidenavbar;
