import { Grid } from '@mui/material';
import { useQuestionList } from './hook';
import QuestionInfoCard from './component/QuestionInfoCard';
import { HeadingText, Text } from '../../common/text/TextStyle';
import LoadingWrapper from '../../common/component/LoadingWrapper';
import CustomTab from '../../common/component/CustomTab';
import { UserAllTabOptions, filterQuestionList, userTabOptions } from './util';
import SearchableTextfield from '../../common/SearchableTextfield';

const QuestionList = () => {
  const {
    questionList,
    courseName,
    loading,
    selectedTab,
    setSelectedTab,
    levelType,
    searchedText,
    setSearchedText,
  } = useQuestionList();

  const renderQuestionSection = () => {
    if (filterQuestionList(questionList, levelType, selectedTab, searchedText).length === 0) {
      return (
        <Grid container alignItems={'center'} height={'50vh'} justifyContent={'center'}>
          <Text size='lg' textweight='extraBold'>
            No Questions Found
          </Text>
        </Grid>
      );
    }
    return (
      <>
        {filterQuestionList(questionList, levelType, selectedTab, searchedText).map((que) => (
          <Grid item xs={12} key={que.id} mt={'30px'}>
            <QuestionInfoCard questionInfo={que} />
          </Grid>
        ))}
      </>
    );
  };
  return (
    <Grid container justifyContent={'center'}>
      <Grid item mt={['120px', '120px', '0px']} xs={11} md={10}>
        <Grid container>
          <Grid item xs={12}>
            <HeadingText textheight='54px' size='sm' textweight='bold'>
              Questions {courseName === 'all' ? '' : `Related to ${courseName}`}
            </HeadingText>
          </Grid>
          <Grid item xs={12} mt={'20px'}>
            <Grid container columnSpacing={'20px'} flexWrap={'wrap'} alignItems={'center'}>
              <Grid item xs={12} md={6} order={[2, 1, 1]}>
                <CustomTab
                  tabOption={userTabOptions?.[levelType] || UserAllTabOptions}
                  setTabSelected={setSelectedTab}
                  value={selectedTab}
                  scrollButtons={'auto'}
                  variant='scrollable'
                />
              </Grid>
              <Grid item xs={12} md={6} order={[1, 2, 2]}>
                <SearchableTextfield
                  value={searchedText}
                  onChange={(e) => setSearchedText(e.target.value)}
                  fullWidth
                  placeholder='Search'
                  sx={{
                    background: '#fff',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <LoadingWrapper loading={loading} height='50vh'>
            {renderQuestionSection()}
          </LoadingWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuestionList;
