import { GridCellParams } from '@mui/x-data-grid';
import { QuestionInfoType } from '../type';
import { Text } from '../../../common/text/TextStyle';
import CustomDataGridAction from '../../../common/CustomDataGridAction';
import { format } from 'date-fns';

export const questionListColumn = (
  handleEdit: (list: QuestionInfoType) => void,
  handleDelete: (questionId: string) => void,
) => [
  {
    field: 'adminHeading',
    headerName: 'Heading',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<QuestionInfoType>) => (
      <Text>{params.row.adminHeading}</Text>
    ),
  },
  {
    field: 'created',
    headerName: 'Created At',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<QuestionInfoType>) => (
      <Text>{params.row.createdAt && format(new Date(params.row.createdAt), 'dd MMM yyyy')}</Text>
    ),
  },
  {
    field: 'updated',
    headerName: 'Updated At',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<QuestionInfoType>) => (
      <Text>{params.row.updatedAt && format(new Date(params.row.updatedAt), 'dd/MMM/yyyy')}</Text>
    ),
  },
  {
    field: 'id',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<QuestionInfoType>) => (
      <CustomDataGridAction
        onDeleteClick={() => handleDelete(params.row.id)}
        onEditClick={() => handleEdit(params.row)}
      />
    ),
  },
];
