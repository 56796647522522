import { Box, Chip, Drawer, Grid } from '@mui/material';
import { Text } from '../../../../common/text/TextStyle';
import { AskDoubtQuestionListType } from '../../../myquestionlist/type';
import { QuestionTypeOptions } from '../../../../constant/OptConstant';
import TextEditor from '../../../../common/field/CustomQuillEditor';
import { useForm } from 'react-hook-form';
import CustomTextInput from '../../../../common/field/CustomTextfield';
import { PrimaryButton } from '../../../../common/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { UpdateAnswerPayloadType } from '../type';
import { updateQuestionAnswer } from '../../../../database/datahelper';
import { toast } from 'react-toastify';
import { useState } from 'react';

type AskDoubtQuestionDetailDrawerProps = {
  questionInfo: AskDoubtQuestionListType;
  handleClose: () => void;
  refreshList: () => void;
};

const AskDoubtQuestionDetailDrawer = ({
  questionInfo,
  handleClose,
  refreshList,
}: AskDoubtQuestionDetailDrawerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      answer: questionInfo.answer || '',
      notes: questionInfo.notes || '',
    },
    resolver: yupResolver(
      object().shape({
        answer: string().required('Please give answer'),
        notes: string(),
      }),
    ),
  });

  const onSubmitAnswer = (value: UpdateAnswerPayloadType) => {
    setIsLoading(true);
    updateQuestionAnswer(value, questionInfo.questionId)
      .then(() => {
        refreshList();
      })
      .catch(() => {
        toast.error('Ask doubt question detail');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      anchor='right'
      open
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '50%',
        },
      }}
    >
      <Grid container p={'30px'}>
        <Grid item xs={12}>
          <Text size='xl' textweight='bold'>
            Update Answer
          </Text>
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <Grid container border={'1px #efefef solid'} p={'20px'}>
            <Grid item xs={12} mt={'10px'}>
              <Box alignItems={'center'} gap={'5px'} display={'flex'} flexWrap={'wrap'}>
                <Chip
                  label={
                    QuestionTypeOptions.find((que) => que.value === questionInfo.questionCategory)
                      ?.label
                  }
                />
                {questionInfo.subjectTags.map((tag) => (
                  <Chip
                    label={QuestionTypeOptions.find((que) => que.value === tag)?.label}
                    key={tag}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} mt={'20px'}>
              <Text size='xl' textweight='bold'>
                {questionInfo.title}
              </Text>
            </Grid>
            <Grid item xs={12} mt={'20px'}>
              <div
                dangerouslySetInnerHTML={{
                  __html: questionInfo.body,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <TextEditor
            descriptionText='Include all the information someone would need to get the answer of the question'
            isMandatory
            control={control}
            name='answer'
            minHeight='30vh'
            label='Answer'
          />
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <CustomTextInput
            multiline
            rows={4}
            register={register}
            name='notes'
            label='Notes'
            fullWidth
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12}>
          <Box width={'200px'} mt={'20px'}>
            <PrimaryButton
              isLoading={isLoading}
              onClick={handleSubmit(onSubmitAnswer)}
              title='Submit'
            />
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default AskDoubtQuestionDetailDrawer;
