import Styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl/FormControl';
import { STYLED_SELECT_COLOR, TEXT_COLOR_THEME } from '../../theme/color';

type StyledTextfieldContainerProps = {
  iserror: number;
};
export const StyledTextfieldContainer = Styled(Grid)<StyledTextfieldContainerProps>`
    & .MuiInput-root {
        :after {
            border-bottom: 1px solid ${(props) =>
              props.iserror ? TEXT_COLOR_THEME.error : TEXT_COLOR_THEME.primary};
        }
    }
    & .MuiInputBase-root:hover {
        :before {
            border-bottom: 1px solid ${(props) =>
              props.iserror ? TEXT_COLOR_THEME.error : TEXT_COLOR_THEME.primary};  
        } 
    }
`;

export const StyledSelectField = Styled(FormControl)`
& .MuiOutlinedInput-root {
    background: ${STYLED_SELECT_COLOR.background};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

& .MuiSelect-select {
    display: flex;
    align-items: center;
}
`;

export const ToggleButtonContainer = Styled(Box)`
    & .MuiButtonBase-root {
        color: ${TEXT_COLOR_THEME.primary};
    }

    & .Mui-selected {
        background: #7d003b21 !important;
        color: ${TEXT_COLOR_THEME.primary} !important;
    }
`;

type StyledTextEditorProps = {
  minHeight?: string;
};
export const StyledTextEditor = Styled(Box)<StyledTextEditorProps>`

    .ql-editor {
        min-height: ${(props) => props.minHeight || '110px'};
    }
    .ql-tooltip {
        left: 20% !important;
        top: 30px !important;
        input[type=text] {
            width: 200px;
        }
    }

.ql-toolbar {
    background-color: white;
}
`;
