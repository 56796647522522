import {
  Box,
  Chip,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { Text } from '../text/TextStyle';
import { ColorTheme, TEXT_COLOR_THEME } from '../../theme/color';
import { OptionType } from '../component/type';

type CustomMultiSelectProps = {
  control: Control<any>;
  name: string;
  textlabel?: string;
  isMandatory?: boolean;
  options: OptionType[];
} & SelectProps;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomMultiSelect = ({
  control,
  name,
  textlabel,
  isMandatory,
  options,
  ...rest
}: CustomMultiSelectProps) => {
  const handleChange = (event: SelectChangeEvent<any>, onChange: any) => {
    const {
      target: { value },
    } = event;

    onChange(
      // On autofill we get a stringified value.
      value,
    );
  };

  return (
    <Box width={'100%'}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <Text
              size='sm'
              textcolor={fieldState.error ? ColorTheme.system.error[400] : ''}
              textweight='regular'
            >
              {textlabel}
              {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
            </Text>
            <Select
              labelId='demo-multiple-chip-label'
              id='demo-multiple-chip'
              multiple
              value={field.value}
              onChange={(e) => handleChange(e, field.onChange)}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {rest.multiple ? (
                    selected.map((value: any) => (
                      <Chip key={value} label={options.find((opt) => opt.value === value)?.label} />
                    ))
                  ) : (
                    <Chip label={options.find((opt) => opt.value === field.value)?.label} />
                  )}
                </Box>
              )}
              MenuProps={MenuProps}
              {...rest}
            >
              {options.map((opt) => (
                <MenuItem
                  key={opt.value}
                  value={opt.value}
                  // style={getStyles(name, personName, theme)}
                >
                  {opt.label}
                </MenuItem>
              ))}
            </Select>

            {fieldState.error && (
              <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default CustomMultiSelect;
