import { Box, Dialog, Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { Text } from '../../common/text/TextStyle';
import StudentLoginForm from '../studentauth/component/StudentLoginForm';
import StudentRegisterForm from '../studentauth/component/StudentRegisterForm';
import StudentForgotPassword from '../studentauth/component/StudentForgotPassword';

type LoginModalProps = {
  toggleLoginModal: () => void;
  isOpen: boolean;
};
const LoginModal = ({ toggleLoginModal, isOpen }: LoginModalProps) => {
  const [formType, setFormType] = useState('loginForm');

  const renderFormView = () => {
    if (formType === 'loginForm') {
      return <StudentLoginForm changeForm={setFormType} handleClose={toggleLoginModal} />;
    }
    if (formType === 'registerForm') {
      return <StudentRegisterForm changeForm={setFormType} handleClose={toggleLoginModal} />;
    }
    return <StudentForgotPassword changeForm={setFormType} handleClose={toggleLoginModal} />;
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '20px',
        },
      }}
      open={isOpen}
      maxWidth={'xs'}
      fullWidth
      onClose={toggleLoginModal}
    >
      <Grid container>
        <Grid
          position={'relative'}
          borderBottom={`1px solid #dee2e6`}
          item
          alignItems={'center'}
          xs={12}
          display={'flex'}
          py={'0.7rem'}
          px={'1rem'}
          justifyContent={'center'}
        >
          <Box position={'absolute'} left={'10px'}>
            <IconButton onClick={toggleLoginModal}>
              <IoMdCloseCircleOutline />
            </IconButton>
          </Box>

          <Text size={'md'} textweight={'bold'}>
            Welcome back!
          </Text>
        </Grid>
        <Grid item xs={12} p={'1.8rem'}>
          {renderFormView()}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LoginModal;
