import { OptionType } from '../common/component/type';

export const LevelTagsOptions: OptionType[] = [
  {
    label: 'JEE MAINS',
    value: 'jeemains',
  },
  {
    label: 'JEE ADVANCE',
    value: 'jeeadvance',
  },
  {
    label: 'NEET',
    value: 'neet',
  },
  {
    label: 'Boards',
    value: 'boards',
  },
  {
    label: 'Olympiad',
    value: 'olympiad',
  },
];
export const SubjectOptions: OptionType[] = [
  {
    label: 'Physics',
    value: 'physics',
  },
  {
    label: 'Chemistry',
    value: 'chem',
  },
  {
    label: 'Maths',
    value: 'maths',
  },
  {
    label: 'Zoology',
    value: 'zoology',
  },
  {
    label: 'Botany',
    value: 'botany',
  },
  {
    label: 'Biology',
    value: 'biology',
  },
];
export const QuestionTypeOptions: OptionType[] = [
  {
    label: 'JEE MAINS',
    value: 'jeemains',
  },
  {
    label: 'JEE ADVANCE',
    value: 'jeeadvance',
  },
  {
    label: 'NEET',
    value: 'neet',
  },
  {
    label: 'Boards',
    value: 'boards',
  },
  {
    label: 'Olympiad',
    value: 'olympiad',
  },
  {
    label: 'Physics',
    value: 'physics',
  },
  {
    label: 'Chemistry',
    value: 'chem',
  },
  {
    label: 'Maths',
    value: 'maths',
  },
  {
    label: 'Zoology',
    value: 'zoology',
  },
  {
    label: 'Botany',
    value: 'botany',
  },
  {
    label: 'Biology',
    value: 'biology',
  },
];
