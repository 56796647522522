import { useQuizView } from './hook';
import { Box, Grid } from '@mui/material';
import QuestionAnswerView from './component/QuestionAnswerView';
import LoadingWrapper from '../../common/component/LoadingWrapper';
import { PrimaryButton } from '../../common/Button';

const QuizView = () => {
  const { questionList, currentQuestionNumber, loading, setCurrentQuestionNumber, navigate } =
    useQuizView();
  return (
    <Grid container pb={'120px'} justifyContent={'center'}>
      <LoadingWrapper loading={loading}>
        <Grid item xs={10}>
          <QuestionAnswerView question={questionList?.[currentQuestionNumber]} />
        </Grid>
        <Grid item xs={12} mt={'20px'}>
          <Box display={'flex'} gap={'10px'} justifyContent={'center'}>
            <Box width={'120px'}>
              <PrimaryButton
                onClick={() => {
                  if (currentQuestionNumber !== 0) {
                    setCurrentQuestionNumber((prev) => prev - 1);
                    navigate(`/quiz?questionno=${currentQuestionNumber - 1}`);
                  }
                }}
                disabled={currentQuestionNumber === 0}
                title={'Previous'}
              />
            </Box>
            <Box width={'120px'}>
              <PrimaryButton
                onClick={() => {
                  if (currentQuestionNumber <= questionList.length - 1) {
                    setCurrentQuestionNumber((prev) => prev + 1);
                    navigate(`/quiz?questionno=${currentQuestionNumber + 1}`);
                  }
                }}
                disabled={currentQuestionNumber === questionList.length - 1}
                title='Next'
              />
            </Box>
          </Box>
        </Grid>
      </LoadingWrapper>
    </Grid>
  );
};

export default QuizView;
