import { object, ref, string } from 'yup';
import { RegisterType } from './type';

export const loginInitialValue = {
  email: '',
  password: '',
};

export const forgotPasswordInitialValue = {
  email: '',
};

export const registerInitialValue: RegisterType = {
  email: '',
  password: '',
  confirmPassword: '',
};

export const loginValidationSchema = object().shape({
  email: string().email('Please enter valid email').required('Please enter email'),
  password: string().required('Please enter password'),
});

export const registerValidationSchema = object().shape({
  email: string().email('Please enter valid email').required('Please enter email'),
  password: string().required('Please enter password'),
  confirmPassword: string().oneOf([ref('password')], 'Passwords must match'),
});

export const forgotPasswordValidationSchema = object().shape({
  email: string().email('Please enter valid email').required('Please enter email'),
});
