import { Grid } from '@mui/material';
import { coursesList } from '../util';
import OnlineCourseCard from './OnlineCourseCard';
import { useNavigate } from 'react-router-dom';
import { HeadingText } from '../../../common/text/TextStyle';

const OnlineCourses = () => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={'40px'}>
      <Grid item xs={12}>
        <HeadingText size='sm' textweight='bold'>
          ONLINE QUESTIONS
        </HeadingText>
      </Grid>

      {coursesList.map((course) => (
        <Grid item xs={12} md={4} key={course.id}>
          <OnlineCourseCard cardInfo={course} handleExploreClick={(url) => navigate(url)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OnlineCourses;
