import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Paper,
} from '@mui/material';
import { QuestionInfoType } from '../../dashboard/type';
import { HeadingText, Text } from '../../../common/text/TextStyle';
import { removeTags } from '../../../util/SiteUtil';
import { ColorTheme } from '../../../theme/color';
import { useNavigate } from 'react-router-dom';
import { QuestionTypeOptions } from '../../../constant/OptConstant';
import { MdKeyboardArrowUp } from 'react-icons/md';

type QuestionInfoCardProps = {
  questionInfo: QuestionInfoType;
};
const QuestionInfoCard = ({ questionInfo }: QuestionInfoCardProps) => {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper
          elevation={2}
          sx={{
            padding: '15px 30px',
            borderRadius: '10px',
          }}
        >
          <Accordion
            sx={{
              boxShadow: 'none',
              padding: 0,
            }}
          >
            <AccordionSummary
              expandIcon={<MdKeyboardArrowUp style={{ fontSize: 30 }} />}
              sx={{
                padding: 0,
              }}
            >
              <Box
                flexWrap={'wrap'}
                alignItems={'center'}
                display={'flex'}
                width={'100%'}
                justifyContent={'space-between'}
              >
                <Box>
                  <HeadingText textheight='32px' size='xs' textweight='bold'>
                    {questionInfo.participantHeading}
                  </HeadingText>
                </Box>

                <Box alignItems={'center'} gap={'5px'} display={'flex'} flexWrap={'wrap'}>
                  {questionInfo.questionLevel.map((tag) => (
                    <Chip
                      label={QuestionTypeOptions.find((que) => que.value === tag)?.label}
                      key={tag}
                    />
                  ))}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 0,
              }}
            >
              <Text
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
                mt={'20px'}
                size='md'
                textweight='regular'
              >
                {removeTags(questionInfo.question)}
              </Text>
            </AccordionDetails>
          </Accordion>

          <Box mt={'10px'} display={'flex'} justifyContent={'space-between'}>
            <Text
              textcolor={ColorTheme.secondary[1000]}
              size='md'
              sx={{
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                navigate(`/questionInfo/${questionInfo.id}`);
              }}
              textweight='regular'
            >
              Click to check answer
            </Text>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default QuestionInfoCard;
