import { Box, Grid, IconButton } from '@mui/material';
import { Text } from '../../common/text/TextStyle';
import { ColorTheme } from '../../theme/color';
import { FaTelegram } from 'react-icons/fa';
import { RiInstagramFill, RiYoutubeFill } from 'react-icons/ri';
import { MdEmail } from 'react-icons/md';

const Footer = () => {
  return (
    <Grid container mt={'100px'} pb={'100px'} bgcolor={ColorTheme.base.white}>
      <Grid item xs={12} p={'30px'}>
        <Text textAlign={'center'}>@copyright Doubt JEE</Text>
      </Grid>
      <Box display={'flex'} width={'100%'} justifyContent={'center'}>
        <Box>
          <IconButton onClick={() => window.open('https://t.me/JEE_NEET_Doubt_solver', '_blank')}>
            <FaTelegram />
          </IconButton>
        </Box>

        <Box>
          <IconButton
            onClick={() => window.open('https://www.instagram.com/andy_sir_24/', '_blank')}
          >
            <RiInstagramFill />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            onClick={() =>
              window.open('https://youtube.com/@AndySirMaths?feature=shared', '_blank')
            }
          >
            <RiYoutubeFill />
          </IconButton>
        </Box>
        <Box>
          <IconButton onClick={() => window.open('mailto:jeedoubt@gmail.com', '_blank')}>
            <MdEmail />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default Footer;
