export type StudentUserData = {
  userInfo: UserInfoType | null;
  token: string;
  expiryToke: string;
  refreshToken: string;
  error: string | null;
  success: boolean;
  loading: boolean;
};

export type UserInfoType = {
  type: 'Student' | 'Admin';
  displayName: string;
  firstName?: string;
  lastName?: string;
  email: string;
  photoUrl: string;
  userId: string;
  phoneNumber: string;
  yearOfBirth?: string;
  gender?: string;
};

export const STUDENT_AUTH = 'STUDENT_AUTH';
