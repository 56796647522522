import { Grid, Popover } from '@mui/material';
import { FC } from 'react';
import { OptionButton } from '../style';
import { FiLogOut } from 'react-icons/fi';
import { useAppDispatch } from '../../../store/hook';
import { userLogout } from '../../../store/auth/api';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { clearUser } from '../../../store/auth';
import { routeUrl } from '../../route/url';

type ProfileModalProps = {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
};

const ProfileModal: FC<ProfileModalProps> = ({ anchorEl, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signout = () => {
    dispatch(userLogout())
      .then(() => {
        dispatch(clearUser());
        navigate(routeUrl.login);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <Popover
      id={'profile-modal'}
      open
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 165,
        },
      }}
    >
      <Grid container p={'20px'}>
        <Grid item xs={12} mt={'10px'}>
          <OptionButton onClick={signout} display={'flex'} gap={'10px'} alignItems={'center'}>
            <FiLogOut />
            Signout
          </OptionButton>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default ProfileModal;
