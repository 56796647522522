import { useEffect, useState } from 'react';
import { getDoubtQuestionList } from '../../../database/datahelper';
import { AskDoubtQuestionListType } from '../../myquestionlist/type';

export const useAskDoubtAdmin = () => {
  const [questionList, setQuestionList] = useState<AskDoubtQuestionListType[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<AskDoubtQuestionListType | undefined>(
    undefined,
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getDoubtQuestionList().then((res) => {
      setQuestionList(res);
      setSelectedQuestion(undefined);
    });
  };

  return {
    questionList,
    setSelectedQuestion,
    selectedQuestion,
    refreshList: fetchData,
  };
};
