export const coursesList = [
  {
    id: 1,
    title: 'JEE Main',
    descriptionText: 'Online Courses',
    exploreText: 'Explore Mains Questions',
    url: '/questions?type=jeemains',
    type: 'jeemains',
  },
  {
    id: 2,
    title: 'JEE Advance',
    descriptionText: 'Online Courses',
    exploreText: 'Explore Advance Questions',
    url: '/questions?type=jeeadvance',
    type: 'jeeadvance',
  },
  {
    id: 3,
    title: 'NEET',
    descriptionText: 'Online Courses',
    exploreText: 'Explore NEET Questions',
    url: '/questions?type=neet',
    type: 'neet',
  },
  {
    id: 4,
    title: 'Olympiad',
    descriptionText: 'Online Courses',
    exploreText: 'Explore Olympiad Questions',
    url: '/questions?type=olympiad',
    type: 'olympiad',
  },
  // {
  //   id: 5,
  //   title: 'PDFs',
  //   descriptionText: 'Online Courses PDFs',
  //   exploreText: 'Explore PDFs',
  //   url: '/pdflist',
  //   type: 'pdf',
  // },
];
