import { Dialog, Grid } from '@mui/material';
import { HeadingText, Text } from '../../../common/text/TextStyle';
import { ColorTheme } from '../../../theme/color';

type BannerModalProps = {
  handleClose: () => void;
};
const renderPdfSection = (
  title: string,
  descriptionText: string,
  url: {
    mathsUrl: {
      question: string;
      answer: string;
    };
    physicsUrl: {
      question: string;
      answer: string;
    };
    chemistryUrl: {
      question: string;
      answer: string;
    };
  },
) => {
  return (
    <Grid
      container
      boxShadow={'0 1px 3px rgba(3,0,71,.09)'}
      borderRadius={'10px'}
      px={['10px', '10px', '40px']}
      py={['20px', '20px', '20px']}
      bgcolor={ColorTheme.base.white}
    >
      <Grid item xs={12}>
        <HeadingText size='xs' textweight='bold'>
          {title}
        </HeadingText>
        <Text
          mt={'10px'}
          textcolor={ColorTheme.secondary[1000]}
          size='md'
          sx={{
            cursor: 'pointer',
            ':hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={() => url.physicsUrl.answer && window.open(url.physicsUrl.answer, '_blank')}
          textweight='regular'
        >
          {descriptionText} {!url.mathsUrl.answer ? '(Coming Soon)' : ''}
        </Text>
        {/* <Grid container rowSpacing={'20px'}>
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              <Grid item xs={4}>
                <Text
                  textcolor={ColorTheme.base.black}
                  size='md'
                  sx={{
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  textweight='regular'
                >
                  Maths PDF
                </Text>
              </Grid>
              <Grid item xs={8}>
                <Box>
                  <Text
                    textcolor={ColorTheme.secondary[1000]}
                    size='md'
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() =>
                      url.mathsUrl.question && window.open(url.mathsUrl.question, '_blank')
                    }
                    textweight='regular'
                  >
                    Question PDF {!url.mathsUrl.question ? '(Coming Soon)' : ''}
                  </Text>
                  <Text
                    textcolor={ColorTheme.secondary[1000]}
                    size='md'
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() =>
                      url.mathsUrl.answer && window.open(url.mathsUrl.answer, '_blank')
                    }
                    textweight='regular'
                  >
                    Answer PDF {!url.mathsUrl.answer ? '(Coming Soon)' : ''}
                  </Text>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              <Grid item xs={4}>
                <Text
                  textcolor={ColorTheme.base.black}
                  size='md'
                  sx={{
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  textweight='regular'
                >
                  Physics PDF
                </Text>
              </Grid>
              <Grid item xs={8}>
                <Box>
                  <Text
                    textcolor={ColorTheme.secondary[1000]}
                    size='md'
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() =>
                      url.physicsUrl.question && window.open(url.physicsUrl.question, '_blank')
                    }
                    textweight='regular'
                  >
                    Question PDF {!url.physicsUrl.question ? '(Coming Soon)' : ''}
                  </Text>
                  <Text
                    textcolor={ColorTheme.secondary[1000]}
                    size='md'
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() =>
                      url.physicsUrl.answer && window.open(url.physicsUrl.answer, '_blank')
                    }
                    textweight='regular'
                  >
                    Answer PDF {!url.physicsUrl.answer ? '(Coming Soon)' : ''}
                  </Text>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              <Grid item xs={4}>
                <Text
                  textcolor={ColorTheme.base.black}
                  size='md'
                  sx={{
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  textweight='regular'
                >
                  Chemistry PDF
                </Text>
              </Grid>
              <Grid item xs={8}>
                <Box>
                  <Text
                    textcolor={ColorTheme.secondary[1000]}
                    size='md'
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() =>
                      url.chemistryUrl.question && window.open(url.chemistryUrl.question, '_blank')
                    }
                    textweight='regular'
                  >
                    Question PDF {!url.chemistryUrl.question ? '(Coming Soon)' : ''}
                  </Text>
                  <Text
                    textcolor={ColorTheme.secondary[1000]}
                    size='md'
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() =>
                      url.chemistryUrl.answer && window.open(url.chemistryUrl.answer, '_blank')
                    }
                    textweight='regular'
                  >
                    Answer PDF {!url.chemistryUrl.answer ? '(Coming Soon)' : ''}
                  </Text>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
};
const BannerModal = ({ handleClose }: BannerModalProps) => {
  return (
    <Dialog fullWidth open maxWidth={'lg'} onClose={handleClose}>
      <Grid bgcolor={'#efefef'} container>
        <Grid item xs={12}>
          <HeadingText size='sm' textAlign={'center'}>
            JEE ADVANCE
          </HeadingText>
        </Grid>
        <Grid item xs={12} md={6} p={'30px'}>
          {renderPdfSection('Question Paper 1', 'Paper 1 Solution', {
            chemistryUrl: {
              answer:
                'https://drive.google.com/file/d/1BWX64gevT3AKiV9UBgyWHpu7zbU2R2yd/view?usp=drivesdk',
              question:
                'https://drive.google.com/file/d/1BWX64gevT3AKiV9UBgyWHpu7zbU2R2yd/view?usp=drivesdk',
            },
            mathsUrl: {
              answer:
                'https://drive.google.com/file/d/1BWX64gevT3AKiV9UBgyWHpu7zbU2R2yd/view?usp=drivesdk',
              question: 'https://drive.google.com/file/d/1Mcw4zWbvxUSKxbs6WMTOSOorDk-fNU6J/view',
            },
            physicsUrl: {
              answer:
                'https://drive.google.com/file/d/1BWX64gevT3AKiV9UBgyWHpu7zbU2R2yd/view?usp=drivesdk',
              question:
                'https://drive.google.com/file/d/1BWX64gevT3AKiV9UBgyWHpu7zbU2R2yd/view?usp=drivesdk',
            },
          })}
        </Grid>
        <Grid item xs={12} md={6} p={'30px'}>
          {renderPdfSection('Question Paper 2', 'Paper 2 Solution', {
            chemistryUrl: {
              answer:
                'https://drive.google.com/file/d/1uk4oGgxYcOF0vGo4tPbIILTeXkAjNMQF/view?usp=drivesdk',
              question:
                'https://drive.google.com/file/d/1uk4oGgxYcOF0vGo4tPbIILTeXkAjNMQF/view?usp=drivesdk',
            },
            mathsUrl: {
              answer:
                'https://drive.google.com/file/d/1uk4oGgxYcOF0vGo4tPbIILTeXkAjNMQF/view?usp=drivesdk',
              question: 'https://drive.google.com/file/d/14Z14jXPbYUEMNBUOrG8Iz_OnhhL8HsMK/view',
            },
            physicsUrl: {
              answer:
                'https://drive.google.com/file/d/1uk4oGgxYcOF0vGo4tPbIILTeXkAjNMQF/view?usp=drivesdk',
              question:
                'https://drive.google.com/file/d/1uk4oGgxYcOF0vGo4tPbIILTeXkAjNMQF/view?usp=drivesdk',
            },
          })}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default BannerModal;
