import React from 'react';
import { useAppSelector } from '../../store/hook';
import { useStudentUserDetails } from '../../store/userauth/selector';
import { Navigate } from 'react-router-dom';
import { routeUrl } from './url';

type Props = {
  children: JSX.Element;
};
const StudentPrivateRoute = ({ children }: Props) => {
  const studentInfo = useAppSelector(useStudentUserDetails);
  if (!studentInfo) {
    return <Navigate to={routeUrl.homescreen} />;
  }

  return <>{children}</>;
};

export default StudentPrivateRoute;
