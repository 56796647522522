/* eslint-disable jsx-a11y/iframe-has-title */
import { Box, Grid } from '@mui/material';
import React from 'react';
import { _url } from '../../util/SiteUtil';
import { useGetWindowSize } from '../../common/hook';
import { Text } from '../../common/text/TextStyle';
import { ColorTheme } from '../../theme/color';
import { useNavigate } from 'react-router-dom';
import { routeUrl } from '../route/url';

const PageNotFound = () => {
  const { mobileView } = useGetWindowSize();
  const navigate = useNavigate();
  return (
    <Grid container bgcolor={'#ffd382'} minHeight={'100vh'}>
      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        <Box>
          <img
            width={mobileView ? '100%' : ''}
            src={_url('assets/giphy.gif')}
            alt={'pagenot found'}
          />
          <Text size='lg' textweight='bold' textAlign={'center'}>
            {' '}
            404 PAGE NOT FOUND
          </Text>
          <Text
            size='lg'
            textcolor={ColorTheme.primary[400]}
            textweight='bold'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => navigate(routeUrl.homescreen)}
            textAlign={'center'}
          >
            {' '}
            (Homepage)
          </Text>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
