import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { ILoginUserData } from "./type";
import { firebaseAuth } from "../../firebase";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const userLogin = createAsyncThunk(
  "auth/login",
  async (loginData: ILoginUserData, { rejectWithValue }) => {
    try {
      const result: any = await signInWithEmailAndPassword(
        firebaseAuth,
        loginData.email,
        loginData.password
      );

      const { idToken } = result._tokenResponse;
      return {
        userInfo: {
          email: loginData.email,
        },
        expiryToke: result.user.stsTokenManager.expirationTime,
        refreshToken: result.user.stsTokenManager.refreshToken,
        token: idToken,
      };
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        toast.error(error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      await signOut(firebaseAuth);
      return;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
