import { Grid } from '@mui/material';
import ContactUsForm from './component/ContactUsForm';

const ContactUs = () => {
  return (
    <Grid container p={'40px'} mt={['120px', '120px', '0px']} justifyContent={'center'}>
      <Grid item xs={12} md={4}>
        <ContactUsForm />
      </Grid>
    </Grid>
  );
};

export default ContactUs;
