import { useState } from 'react';
import { LoginContainer, LoginFormContainer } from './style';
import { useForm } from 'react-hook-form';
import { loginInitialValue, loginValidationSchema } from './util';
import { Box, Grid, IconButton, InputAdornment } from '@mui/material';
import CustomTextInput from '../../common/field/CustomTextfield';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { PrimaryButton } from '../../common/Button';
import { LoginType } from './type';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../store/hook';
import { userLogin } from '../../store/auth/api';
import { routeUrl } from '../route/url';
import { usePressEnterHook } from '../../common/hook';
import { Text } from '../../common/text/TextStyle';
import { ColorTheme, TEXT_COLOR_THEME } from '../../theme/color';
import { LogoStyle } from '../sidenavbar/style';

const Login = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitialValue,
    resolver: yupResolver(loginValidationSchema),
  });

  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (values: LoginType) => {
    setLoading(true);
    dispatch(userLogin(values))
      .then((res: any) => {
        if (!res.error) {
          navigate(routeUrl.dashboard);
          return;
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  usePressEnterHook(handleSubmit(onSubmit));
  return (
    <LoginContainer
      container
      minHeight={'100vh'}
      width={'100vw'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <LoginFormContainer item xs={12} sm={6} md={4}>
        <Grid container spacing={'20px'}>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'center'}>
              <Text size='xl' textweight='bold' textAlign={'center'}>
                <LogoStyle>
                  <span style={{ fontWeight: 'bold', color: TEXT_COLOR_THEME.dark }}>EDU</span>{' '}
                  <span style={{ fontWeight: 300 }}>HUB</span>
                </LogoStyle>
              </Text>
            </Box>
          </Grid>

          <Grid item xs={12} mt={'20px'}>
            <CustomTextInput
              register={register}
              name={'email'}
              fullWidth
              label={'Email'}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextInput
              fullWidth
              register={register}
              type={showPassword ? 'text' : 'password'}
              name={'password'}
              label={'Password'}
              errors={errors}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                      {!showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {error && (
            <Grid item xs={12} textAlign={'center'}>
              <Text size='sm' textcolor={ColorTheme.system.error[400]}>
                {error}
              </Text>
            </Grid>
          )}
          <Grid item xs={12}>
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              fullWidth
              isLoading={loading}
              title={'Login'}
            />
          </Grid>
          <Grid display={'flex'} justifyContent={'space-between'} item xs={12}>
            <Text textweight={'regular'} size='sm'>
              <span
                style={{ fontWeight: 800, cursor: 'pointer' }}
                onClick={() => navigate('/forgotPassword')}
              >
                {' '}
                Forgot Password?
              </span>
            </Text>
            <Text textweight={'regular'} size='sm'>
              Don't have an Account?{' '}
              <span
                style={{ fontWeight: 800, cursor: 'pointer' }}
                onClick={() => navigate(routeUrl.register)}
              >
                {' '}
                Register
              </span>
            </Text>
          </Grid>
        </Grid>
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default Login;
