import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { AiFillEdit, AiFillEye } from 'react-icons/ai';
import { BUTTON_COLOR_THEME } from '../theme/color';
import { MdDeleteOutline } from 'react-icons/md';
// import { Description1 } from "./Text";
import { CiMenuKebab } from 'react-icons/ci';
import { Text } from './text/TextStyle';

type CustomDataGridActionProps = {
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onViewClick?: () => void;
};
const CustomDataGridAction: FC<CustomDataGridActionProps> = ({
  onDeleteClick,
  onEditClick,
  onViewClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <CiMenuKebab />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        {onViewClick && (
          <MenuItem
            onClick={() => {
              onViewClick();
              setAnchorEl(null);
            }}
          >
            <AiFillEye color={BUTTON_COLOR_THEME.lightDark} style={{ fontSize: 20 }} />
            <Text size='sm' textweight='regular' ml={'5px'}>
              View
            </Text>
          </MenuItem>
        )}
        {onEditClick && (
          <MenuItem
            onClick={() => {
              onEditClick();
              setAnchorEl(null);
            }}
          >
            <AiFillEdit color={BUTTON_COLOR_THEME.lightDark} style={{ fontSize: 20 }} />
            <Text size='sm' textweight='regular' ml={'5px'}>
              Edit
            </Text>
          </MenuItem>
        )}
        {onDeleteClick && (
          <MenuItem
            onClick={() => {
              onDeleteClick();
              setAnchorEl(null);
            }}
          >
            <MdDeleteOutline style={{ color: BUTTON_COLOR_THEME.danger, fontSize: 20 }} />{' '}
            <Text size='sm' textweight='regular' ml={'5px'}>
              Delete
            </Text>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default CustomDataGridAction;
