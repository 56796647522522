import { useEffect, useState } from 'react';
import { QuestionInfoType } from './type';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { questionInitialValue, questionValidationSchema } from './util';
import {
  addQuestion,
  deleteQuestion,
  getQuestionList,
  updateQuestion,
} from '../../database/datahelper';
import { toast } from 'react-toastify';

export const useDashboard = () => {
  const [openAddQuestionDrawer, setOpenAddQuestionDrawer] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionInfoType | undefined>(undefined);
  const [selectedQuestionForDelete, setSelectedQuestionForDelete] = useState<string | undefined>(
    undefined,
  );
  const [questionList, setQuestionList] = useState<QuestionInfoType[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    getQuestionList().then((res) => setQuestionList(res));
  };

  const handleDelete = () => {
    if (!selectedQuestionForDelete) {
      return;
    }
    setDeleteLoading(true);
    deleteQuestion(selectedQuestionForDelete)
      .then(() => {
        setSelectedQuestionForDelete(undefined);
        fetchList();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  return {
    selectedQuestion,
    selectedQuestionForDelete,
    setSelectedQuestion,
    setSelectedQuestionForDelete,
    openAddQuestionDrawer,
    setOpenAddQuestionDrawer,
    refreshList: fetchList,
    questionList,
    setDeleteLoading,
    deleteLoading,
    handleDelete,
  };
};

export const useQuestionDetailDrawer = (
  selectedQuestion?: QuestionInfoType,
  refreshList?: () => void,
  handleClose?: () => void,
) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: questionInitialValue(selectedQuestion),
    resolver: yupResolver(questionValidationSchema as any),
  });
  const [loading, setLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState('form');

  const onSubmit = (values: QuestionInfoType) => {
    setLoading(true);
    if (selectedQuestion) {
      updateQuestion({
        ...values,
        questionLevel: [...values.questionLevel, ...values.subjectTags],
      })
        .then(() => {
          refreshList && refreshList();
          handleClose && handleClose();
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }
    addQuestion({
      ...values,
      questionLevel: [...values.questionLevel, ...values.subjectTags],
    })
      .then(() => {
        refreshList && refreshList();
        handleClose && handleClose();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    errors,
    selectedTab,
    setSelectedTab,
    loading,
    onSubmit,
  };
};
