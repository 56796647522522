import { Box, Button, Grid } from "@mui/material";
import { BUTTON_COLOR_THEME, DATAGRID_COLOR } from "../theme/color";
import { FONT_THEME } from "../theme/fontTheme";
import Styled from "@emotion/styled";

export const StyledButton = Styled(Button)`
    background: ${BUTTON_COLOR_THEME.primary};
    color: ${BUTTON_COLOR_THEME.light};
    font-family: ${FONT_THEME.primary};
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    :hover {
        background: ${BUTTON_COLOR_THEME.primary};
        opacity: 0.8;
    }
`;

export const StyledTextButton = Styled(Button)`
    color: ${BUTTON_COLOR_THEME.primary};
    font-family: ${FONT_THEME.primary};
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    :hover {
        opacity: 0.8;
    }
`;

type StyledDataGridProps = {
  nodataheight?: string;
  nopadding?: number;
};

export const StyledDataGrid = Styled(Grid)<StyledDataGridProps>`
& .MuiDataGrid-columnSeparator {
    display: none;
};

& .MuiDataGrid-root {
    border-radius: 13px;
};

& .MuiDataGrid-virtualScrollerContent {
    height: ${(props) => props.nodataheight || undefined} !important;
}

& .MuiDataGrid-columnHeader {
    font-size: 12px;
    outline: none !important;
    background-color: ${DATAGRID_COLOR.headerBackground};
};

& .MuiDataGrid-cell {
    font-size: 12px;
    outline: none !important;
    ${(props) =>
      props.nopadding &&
      `
        padding: 0px !important;
        border-bottom: 0px;
    `};
};

& .MuiDataGrid-columnHeaderTitle {
    color: ${DATAGRID_COLOR.headerColor};
          font-weight: 600 !important;
          text-transform: uppercase;
          letter-spacing: -0.01em;
          overflow: visible;
          text-overflow: unset;
          font-family: ${FONT_THEME.primary};
};

& .MuiDataGrid-columnsContainer {
    border-bottom: none !important;
          border-radius: 12;
          top: 4px;
};

& .MuiDataGrid-row {
    width: unset;
    outline: none !important;
}

& .MuiDataGrid-row:hover {
    background-color: ${DATAGRID_COLOR.headerBackground};
    border-radius: 0px;
}

& .MuiDataGrid-main {
    background-color: white;
          border-radius: 10px;
}

& .MuiDataGrid-window {
    top: 62px !important;
}
& .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
}
`;

export const DropdownStyle = Styled(Box)`
    border: 1px #000 dashed;
    min-height: 100px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const StyledTag = Styled(Box)`
    border-radius: 10px;
    padding: 5px 10px;
`;
