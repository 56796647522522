/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export const usePressEnterHook = (callback?: () => void) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        callback && callback();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
};

export const useGetWindowSize = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(900));
  const desktopView = useMediaQuery(theme.breakpoints.up(900));

  return {
    mobileView,
    desktopView,
  };
};
