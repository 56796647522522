/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from '@mui/material';
import { FC } from 'react';
import { useContactUs } from '../hook';
import { HeadingText } from '../../../common/text/TextStyle';
import { ColorTheme } from '../../../theme/color';
import CustomTextInput from '../../../common/field/CustomTextfield';
import { PrimaryButton } from '../../../common/Button';

type ContactUsFormProps = {
  title?: string;
};

const ContactUsForm: FC<ContactUsFormProps> = ({ title }) => {
  const { errors, form, handleSubmit, register, onSubmit } = useContactUs();

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        borderRadius={'10px'}
        boxShadow={'1px 10px 10px #ccc'}
        container
        bgcolor={'#fff'}
        p={'30px'}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid mb={'30px'} item xs={12}>
              <HeadingText
                textweight='bold'
                size='sm'
                textcolor={ColorTheme.base.primary}
                textAlign={'center'}
              >
                {title || 'Contact Us'}
              </HeadingText>
            </Grid>
            <Grid item xs={12}>
              <CustomTextInput
                name='name'
                register={register}
                errors={errors}
                variant='outlined'
                fullWidth
                placeholder='Name'
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextInput
                name='email'
                register={register}
                errors={errors}
                variant='outlined'
                fullWidth
                placeholder='Email'
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextInput
                name='message'
                register={register}
                errors={errors}
                rows={4}
                variant='outlined'
                fullWidth
                maxRows={4}
                multiline
                placeholder='Message'
              />
            </Grid>
            <Grid item xs={4} mt={'10px'}>
              <PrimaryButton type='submit' title='Submit' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactUsForm;
