import { Grid } from '@mui/material';
import React from 'react';
import PdfList from './component/PdfList';
import { usePdfList } from './hook';
import SearchableTextfield from '../../common/SearchableTextfield';
import { HeadingText } from '../../common/text/TextStyle';
import { filterUserBySearchText } from './util';

const UserPdfsList = () => {
  const { loading, pdfList, searchedText, setSearchedText } = usePdfList();
  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={10} md={11}>
        <Grid container>
          <Grid item xs={12}>
            <HeadingText size='md' textweight='bold' textAlign={'center'}>
              PDFs List
            </HeadingText>
          </Grid>
          <Grid item xs={12} mb={'10px'}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <SearchableTextfield
                  value={searchedText}
                  onChange={(e) => setSearchedText(e.target.value)}
                  fullWidth
                  placeholder='Search by PDF title/Tags'
                  sx={{
                    background: '#fff',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PdfList list={filterUserBySearchText(searchedText, pdfList)} loading={loading} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserPdfsList;
