import React, { FC } from 'react';
import { Box, FormHelperText, IconButton, TextField, TextFieldProps, Tooltip } from '@mui/material';
// import { FieldLabel } from "../Text";
import { StyledTextfieldContainer } from './Style';
import { UseFormRegister, FieldErrors, FieldValues } from 'react-hook-form';
import { TEXT_COLOR_THEME } from '../../theme/color';
import { Text } from '../text/TextStyle';
import { MdInfoOutline } from 'react-icons/md';

type CustomTextInputProps = {
  name: string;
  label?: string;
  showError?: boolean;
  register: UseFormRegister<any>; //TODO: fix type
  errors?: FieldErrors<FieldValues>;
  errorMessage?: string;
  isMandatory?: boolean;
  infoText?: string;
  descriptionText?: string;
} & TextFieldProps;

const CustomTextInput: FC<CustomTextInputProps> = ({
  name,
  label,
  register,
  errors,
  isMandatory,
  errorMessage,
  infoText,
  descriptionText,
  ...rest
}) => {
  return (
    <Box>
      <Text size='sm' display={'flex'} alignItems={'center'} textweight='regular'>
        {label}
        {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
        {infoText && (
          <Tooltip title={infoText}>
            <IconButton>
              <MdInfoOutline />
            </IconButton>
          </Tooltip>
        )}
      </Text>
      {descriptionText && (
        <Text
          size='xs'
          textcolor='grey'
          display={'flex'}
          alignItems={'center'}
          textweight='regular'
        >
          {descriptionText}
        </Text>
      )}

      <StyledTextfieldContainer
        mt={'5px'}
        iserror={errorMessage || (errors && errors[name]?.message) ? 1 : 0}
      >
        <TextField
          {...register(name)}
          name={name}
          autoComplete={'off'}
          size={'small'}
          error={!!(errorMessage || (errors && errors[name]?.message))}
          variant='standard'
          {...rest}
        />
        {errors
          ? [name] && (
              <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
                {errorMessage || (errors[name]?.message as string) || ''}
              </FormHelperText>
            )
          : null}
      </StyledTextfieldContainer>
    </Box>
  );
};

export default CustomTextInput;
