export interface IUserData {
  userInfo: IUserInfo | null;
  token: string;
  expiryToke: string;
  refreshToken: string;
  error: string | null;
  success: boolean;
  loading: boolean;
}
export interface ILoginFormPayload {
  email: string;
  password: string;
}

export interface IUserInfo {
  email: string;
}

export interface ILoginUserData {
  email: string;
  password: string;
}

export const AUTH = "AUTH";
