import { array, object, string } from 'yup';
import { AskDoubtQuestionType } from './type';

export const askDoubtQuestionInitialValue = (): AskDoubtQuestionType => {
  return {
    body: '',
    questionCategory: '',
    subjectTags: [],
    title: '',
  };
};

export const askDoubtValidationSchema = object().shape({
  body: string().required('Please enter question'),
  questionCategory: string().required('Please select question category'),
  subjectTags: array().min(1, 'Please select any one subject tag'),
  title: string().required('Please enter title'),
});
