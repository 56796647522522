import { Box, Grid } from '@mui/material';
import { useAskDoubt } from './hook';
import CustomTextInput from '../../common/field/CustomTextfield';
import TextEditor from '../../common/field/CustomQuillEditor';
import CustomMultiSelect from '../../common/field/CustomMultiSelect';
import { LevelTagsOptions, SubjectOptions } from '../../constant/OptConstant';
import { OptionType } from '../../common/component/type';
import { PrimaryButton } from '../../common/Button';
import { Text } from '../../common/text/TextStyle';

const AskDoubt = () => {
  const { control, isLoading, errors, handleSubmit, postQuestion, questionCategory, register } =
    useAskDoubt();

  const getSubjectTagsOption: Record<string, OptionType[]> = {
    jeemains: SubjectOptions.filter((item) => ['physics', 'chem', 'maths'].includes(item.value)),
    neet: SubjectOptions.filter((item) =>
      ['physics', 'chem', 'zoology', 'botany'].includes(item.value),
    ),
  };
  return (
    <Grid container mt={['120px', '120px', 0]} justifyContent={'center'}>
      <Grid
        item
        xs={11}
        md={6}
        bgcolor={'#fff'}
        border={'1px #efefef solid'}
        padding={'20px'}
        boxShadow={'1px 1px 10px #ccc'}
      >
        <Grid container>
          <Grid item xs={12}>
            <Text size='lg' textweight='bold'>
              Ask Your Doubt
            </Text>
          </Grid>
          <Grid mt={'30px'} item xs={12}>
            <CustomTextInput
              variant='outlined'
              name='title'
              label={'Title'}
              register={register}
              isMandatory
              descriptionText="Be specfic and imagine you're asking a question to another person"
              fullWidth
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <TextEditor
              descriptionText='Include all the information someone would need to answer your question'
              isMandatory
              control={control}
              name='body'
              minHeight='30vh'
              label='Body'
            />
          </Grid>
          <Grid item xs={12}>
            <CustomMultiSelect
              variant='outlined'
              name='questionCategory'
              textlabel={'Category'}
              control={control}
              size='small'
              isMandatory
              multiple={false}
              fullWidth
              options={LevelTagsOptions.filter((item) => ['jeemains', 'neet'].includes(item.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomMultiSelect
              variant='outlined'
              name='subjectTags'
              textlabel={'Subject Tags'}
              control={control}
              size='small'
              isMandatory
              multiple
              fullWidth
              options={getSubjectTagsOption?.[questionCategory] || []}
            />
          </Grid>
          <Grid item xs={12}>
            <Box width={'200px'} mt={'20px'}>
              <PrimaryButton
                isLoading={isLoading}
                onClick={handleSubmit(postQuestion)}
                title='Post your question'
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AskDoubt;
