import { Grid } from '@mui/material';
import React from 'react';
import QuestionAnswerView from '../quiz/component/QuestionAnswerView';
import { useQuestionInfo } from './hook';
import LoadingWrapper from '../../common/component/LoadingWrapper';

const QuestionInfo = () => {
  const { loading, questionInfo } = useQuestionInfo();
  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={10}>
        <LoadingWrapper loading={loading}>
          {questionInfo && <QuestionAnswerView question={questionInfo} />}
        </LoadingWrapper>
      </Grid>
    </Grid>
  );
};

export default QuestionInfo;
