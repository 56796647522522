import { FC } from 'react';
import { QuestionInfoType } from '../type';
import DataGridWrapper from '../../../common/DatagridWrapper';
import { questionListColumn } from './ListColumn';

type QuestionListProps = {
  list: QuestionInfoType[];
  handleEdit: (question: QuestionInfoType) => void;
  handleDelete: (questionId: string) => void;
};
const QuestionList: FC<QuestionListProps> = ({ list, handleDelete, handleEdit }) => {
  return (
    <>
      <DataGridWrapper rows={list} columns={questionListColumn(handleEdit, handleDelete)} />
    </>
  );
};

export default QuestionList;
