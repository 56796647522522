import { useState } from "react";

export const useHeader = () => {
  const [openProfilePopup, setOpenProfilePopup] =
    useState<HTMLButtonElement | null>(null);

  return {
    openProfilePopup,
    setOpenProfilePopup,
  };
};
