import { Box, Grid } from '@mui/material';
import { StyledCard } from '../../common/Card';
import { Text } from '../../common/text/TextStyle';
import { PrimaryButton } from '../../common/Button';
import { usePdfSection } from './hook';
import AddPdfDrawer from './component/AddPdfDrawer';
import PdfDatagrid from './component/PdfDatagrid';
import DeleteConfirmation from '../../common/DeleteConfirmation';

const PdfSection = () => {
  const {
    openAddPdfDrawer,
    deleteLoading,
    handleDelete,
    setOpenAddPdfDrawer,
    selectedPdf,
    setSelectedPdf,
    refreshList,
    pdfList,
    selectedPdfForDelete,
    setSelectedPdfForDelete,
    loading,
  } = usePdfSection();
  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledCard>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Text size='md' textweight='regular'>
              PDF List
            </Text>
            <Box width={'150px'}>
              <PrimaryButton onClick={() => setOpenAddPdfDrawer(true)} title='Add PDF' />
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12} mt={'20px'}>
              <PdfDatagrid
                handleDelete={setSelectedPdfForDelete}
                handleEdit={setSelectedPdf}
                list={pdfList}
                loading={loading}
              />
            </Grid>
          </Grid>
          {(selectedPdf || openAddPdfDrawer) && (
            <AddPdfDrawer
              handleClose={() => {
                setSelectedPdf(undefined);
                setOpenAddPdfDrawer(false);
              }}
              selctedPdf={selectedPdf}
              refreshList={() => {
                refreshList();
              }}
            />
          )}
          {selectedPdfForDelete && (
            <DeleteConfirmation
              isLoading={deleteLoading}
              isOpen
              description='Are you sure you want to delete?'
              modalTitle='PDF Delete'
              handleClose={() => setSelectedPdfForDelete(undefined)}
              handleSubmit={() => handleDelete()}
            />
          )}
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default PdfSection;
