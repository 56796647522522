import { PdfInfoType } from '../pdfs/type';

export const filterUserBySearchText = (searchText: string, list: PdfInfoType[]) => {
  if (searchText === '') {
    return list;
  }

  return list.filter(
    (item) =>
      item.pdfText.toLowerCase().includes(searchText.toLowerCase()) ||
      item.pdfDescription.includes(searchText.toLowerCase()) ||
      item.tag.join(', ').toLowerCase().includes(searchText.toLowerCase()),
  );
};
