import { useState } from 'react';
import { LoginContainer, LoginFormContainer } from './style';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { registerInitialValue, registerValidationSchema } from './util';
import { yupResolver } from '@hookform/resolvers/yup';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '../../firebase';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { usePressEnterHook } from '../../common/hook';
import CustomTextInput from '../../common/field/CustomTextfield';
import { Text } from '../../common/text/TextStyle';
import { ColorTheme, TEXT_COLOR_THEME } from '../../theme/color';
import { PrimaryButton } from '../../common/Button';
import { LogoStyle } from '../sidenavbar/style';
import { routeUrl } from '../route/url';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [error] = useState(null);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: registerInitialValue,
    resolver: yupResolver(registerValidationSchema),
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const onSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    createUserWithEmailAndPassword(firebaseAuth, values.email, values.password)
      .then(() => {
        toast.success('Register successfull! Please Login');
        navigate(routeUrl.login);
      })
      .catch((err: any) => {
        toast.error(err.message || 'Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  usePressEnterHook(handleSubmit(onSubmit));
  return (
    <LoginContainer
      container
      minHeight={'100vh'}
      width={'100vw'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <LoginFormContainer item xs={12} sm={6} md={4}>
        <Grid container spacing={'20px'}>
          <Grid item xs={12}>
            <Text size='xl' textweight='bold' textAlign={'center'}>
              <LogoStyle>
                <span style={{ fontWeight: 'bold', color: TEXT_COLOR_THEME.dark }}>EDU</span>{' '}
                <span style={{ fontWeight: 300 }}>HUB</span>
              </LogoStyle>
            </Text>
          </Grid>

          <Grid item xs={12} mt={'20px'}>
            <CustomTextInput
              register={register}
              name={'email'}
              fullWidth
              label={'Email'}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextInput
              fullWidth
              register={register}
              type={showPassword.password ? 'text' : 'password'}
              name={'password'}
              label={'Password'}
              errors={errors}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() =>
                        setShowPassword((prev) => ({
                          ...prev,
                          password: !prev.password,
                        }))
                      }
                    >
                      {!showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              fullWidth
              register={register}
              type={showPassword.password ? 'text' : 'password'}
              name={'confirmPassword'}
              label={'Confirm Password'}
              errors={errors}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() =>
                        setShowPassword((prev) => ({
                          ...prev,
                          confirmPassword: !prev.confirmPassword,
                        }))
                      }
                    >
                      {!showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {error && (
            <Grid item xs={12} textAlign={'center'}>
              <Text size='sm' textcolor={ColorTheme.system.error[400]}>
                {error}
              </Text>
            </Grid>
          )}
          <Grid item xs={12}>
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              fullWidth
              isLoading={loading}
              title={'Register'}
            />
          </Grid>
          <Grid item xs={12}>
            <Text textweight={'regular'} textsize='14px'>
              Already have an Account?
              {/* ts-ignore */}
              <span
                style={{ fontWeight: 800, cursor: 'pointer' }}
                onClick={() => navigate(routeUrl.login)}
              >
                {' '}
                Login
              </span>
            </Text>
          </Grid>
        </Grid>
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default Register;
