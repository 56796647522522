import './App.css';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './container/route/PrivateRoute';
import { routeUrl } from './container/route/url';
import Login from './container/login/Login';
import Register from './container/login/Register';
import ForgotPassword from './container/login/ForgotPassword';
import Dashboard from './container/dashboard/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import QuizView from './container/quiz/QuizView';
import PublicRoute from './container/route/PublicRoute';
import Homescreen from './container/homescreen/Homescreen';
import PageNotFound from './container/pagenotfound/PageNotFound';
import ScrollToTop from './common/component/ScrollToTop';
import QuestionList from './container/question/QuestionList';
import QuestionInfo from './container/question/QuestionInfo';
import ContactUs from './container/contactus/ContactUs';
import PdfSection from './container/pdfs/PdfSection';
import UserPdfsList from './container/userpdflist/UserPdfsList';
import AskDoubt from './container/askdoubt/AskDoubt';
import AskDoubtQuestionList from './container/myquestionlist/AskDoubtQuestionList';
import StudentPrivateRoute from './container/route/StudentPrivateRoute';
import AskDoubtAdmin from './container/admin/askdoubt/AskDoubtAdmin';

function App() {
  return (
    <>
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        <Route path={'*'} element={<PageNotFound />} />
        <Route
          path={routeUrl.homescreen}
          element={
            <PublicRoute>
              <Homescreen />
            </PublicRoute>
          }
        />
        <Route path={routeUrl.login} element={<Login />} />
        <Route path={routeUrl.register} element={<Register />} />
        <Route path={routeUrl.forgotPassword} element={<ForgotPassword />} />
        <Route
          path={routeUrl.dashboard}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.quiz}
          element={
            <PublicRoute>
              <QuizView />
            </PublicRoute>
          }
        />
        <Route
          path={routeUrl.questionList}
          element={
            <PublicRoute>
              <QuestionList />
            </PublicRoute>
          }
        />
        <Route
          path={routeUrl.questionInfo}
          element={
            <PublicRoute>
              <QuestionInfo />
            </PublicRoute>
          }
        />
        <Route
          path={routeUrl.contactUs}
          element={
            <PublicRoute>
              <ContactUs />
            </PublicRoute>
          }
        />
        <Route
          path={routeUrl.pdfs}
          element={
            <PrivateRoute>
              <PdfSection />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.askDoubtAdmin}
          element={
            <PrivateRoute>
              <AskDoubtAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path={routeUrl.pdfsList}
          element={
            <PublicRoute>
              <UserPdfsList />
            </PublicRoute>
          }
        />
        <Route
          path={routeUrl.askDoubt}
          element={
            <StudentPrivateRoute>
              <PublicRoute>
                <AskDoubt />
              </PublicRoute>
            </StudentPrivateRoute>
          }
        />
        <Route
          path={routeUrl.askDoubtQuestionsList}
          element={
            <StudentPrivateRoute>
              <PublicRoute>
                <AskDoubtQuestionList />
              </PublicRoute>
            </StudentPrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
