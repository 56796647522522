import { Grid } from '@mui/material';
import HomepageHeader from './component/HomepageHeader';
import OnlineCourses from './component/OnlineCourses';

const Homescreen = () => {
  return (
    <Grid container minHeight={'100vh'} justifyContent={'center'}>
      <Grid item xs={12}>
        <HomepageHeader />
      </Grid>
      <Grid item xs={10} mt={'30px'}>
        <OnlineCourses />
      </Grid>
    </Grid>
  );
};

export default Homescreen;
