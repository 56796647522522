import { GridCellParams } from '@mui/x-data-grid';
import { PdfInfoType } from '../../pdfs/type';
import { Box, Chip, IconButton, Tooltip } from '@mui/material';
import { AiFillEye } from 'react-icons/ai';
import { QuestionTypeOptions } from '../../../constant/OptConstant';
import { Text } from '../../../common/text/TextStyle';
import { ColorTheme } from '../../../theme/color';

export const pdfListColumn = (handleViewClick: (url: string) => void) => [
  {
    field: 'adminHeading',
    headerName: 'Pdf Title',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<PdfInfoType>) => (
      <Tooltip title={params.row.pdfDescription}>
        <Text
          textcolor={ColorTheme.secondary[1000]}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={() => handleViewClick(params.row.pdfUrl)}
        >
          {params.row.pdfText}
        </Text>
      </Tooltip>
    ),
  },
  {
    field: 'tag',
    headerName: 'Tags',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<PdfInfoType>) => (
      <Box py={'10px'} flexWrap={'wrap'} gap={'2px'} display={'flex'}>
        {params.row.tag.map((item) => (
          <Chip key={item} label={QuestionTypeOptions.find((opt) => opt.value === item)?.label} />
        ))}
      </Box>
    ),
  },

  {
    field: 'id',
    headerName: '',
    minWidth: 50,
    sortable: false,
    renderCell: (params: GridCellParams<PdfInfoType>) => (
      <IconButton onClick={() => handleViewClick(params.row.pdfUrl)}>
        <AiFillEye />
      </IconButton>
    ),
  },
];
