import { array, object, string } from 'yup';
import { PdfInfoType } from './type';

export const PdfSectionInitialValue = (data?: PdfInfoType): PdfInfoType => ({
  id: data?.id || '',
  pdfText: data?.pdfText || '',
  pdfUrl: data?.pdfUrl || '',
  tag: data?.tag || [],
  pdfDescription: data?.pdfDescription || '',
  createdAt: data?.createdAt || '',
});

export const pdfValidationSchema = object().shape({
  pdfText: string().required('Please enter admin Heading'),
  pdfUrl: string().required('Please enter question'),
  tag: array().min(1, 'Please select any one tag'),
});
