/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { userAskedQuestionList } from '../../database/datahelper';
import { useAppSelector } from '../../store/hook';
import { useStudentUserDetails } from '../../store/userauth/selector';
import { toast } from 'react-toastify';
import { AskDoubtQuestionListType } from './type';

export const useAskDoubtQuestion = () => {
  const userInfo = useAppSelector(useStudentUserDetails);
  const [questionList, setQuestionList] = useState<AskDoubtQuestionListType[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchQuestionList();
  }, []);

  const fetchQuestionList = () => {
    if (userInfo?.userId) {
      setLoading(true);
      userAskedQuestionList(userInfo?.userId)
        .then((res) => {
          setQuestionList(res);
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return {
    questionList,
    searchedText,
    setSearchedText,
    loading,
  };
};
