import { useState } from 'react';
import { LoginContainer, LoginFormContainer } from './style';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { forgotPasswordInitialValue, forgotPasswordValidationSchema } from './util';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { ForgotPasswordType } from './type';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';
import { firebaseAuth } from '../../firebase';
import { usePressEnterHook } from '../../common/hook';
import CustomTextInput from '../../common/field/CustomTextfield';
import { PrimaryButton } from '../../common/Button';
import { Text } from '../../common/text/TextStyle';
import { ColorTheme, TEXT_COLOR_THEME } from '../../theme/color';
import { LogoStyle } from '../sidenavbar/style';
import { routeUrl } from '../route/url';

const ForgotPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: forgotPasswordInitialValue,
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (values: ForgotPasswordType) => {
    setLoading(true);
    sendPasswordResetEmail(firebaseAuth, values.email)
      .then(() => {
        toast.success('Please check your mail and reset your password');
      })
      .catch((err) => {
        toast.error(err.message);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  usePressEnterHook(handleSubmit(onSubmit));
  return (
    <LoginContainer
      container
      minHeight={'100vh'}
      width={'100vw'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <LoginFormContainer item xs={12} sm={6} md={4}>
        <Grid container spacing={'20px'}>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'center'}>
              <Text size='xl' textweight='bold' textAlign={'center'}>
                <LogoStyle>
                  <span style={{ fontWeight: 'bold', color: TEXT_COLOR_THEME.dark }}>EDU</span>{' '}
                  <span style={{ fontWeight: 300 }}>HUB</span>
                </LogoStyle>
              </Text>
            </Box>
          </Grid>

          <Grid item xs={12} mt={'20px'}>
            <CustomTextInput
              register={register}
              name={'email'}
              fullWidth
              label={'Email'}
              errors={errors}
            />
          </Grid>
          {error && (
            <Grid item xs={12} textAlign={'center'}>
              <Text size='sm' textcolor={ColorTheme.system.error[400]}>
                {error}
              </Text>
            </Grid>
          )}
          <Grid item xs={12}>
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              fullWidth
              isLoading={loading}
              title={'Reset Password'}
            />
          </Grid>
          <Grid display={'flex'} justifyContent={'space-between'} item xs={12}>
            <Text textweight={'regular'} textsize='14px'>
              Go to
              <span
                style={{ fontWeight: 800, cursor: 'pointer' }}
                onClick={() => navigate(routeUrl.login)}
              >
                {' '}
                Login!
              </span>
            </Text>
          </Grid>
        </Grid>
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default ForgotPassword;
