import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

type SearchableTextFieldProps = TextFieldProps;
const SearchableTextfield = ({ ...rest }: SearchableTextFieldProps) => {
  return (
    <TextField
      size='small'
      InputProps={{
        startAdornment: (
          <AiOutlineSearch
            style={{
              marginRight: 10,
            }}
          />
        ),
      }}
      variant='outlined'
      {...rest}
    />
  );
};

export default SearchableTextfield;
