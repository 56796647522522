const config = {
  apiKey: 'AIzaSyDJ05Di4y_Am3MoTlHhn3FzRyfkTxdAmV4',
  authDomain: 'doubtjee-d8a37.firebaseapp.com',
  projectId: 'doubtjee-d8a37',
  storageBucket: 'doubtjee-d8a37.appspot.com',
  messagingSenderId: '768560819283',
  appId: '1:768560819283:web:aeb940ef3583c1b215eaa6',
  measurementId: 'G-JRW7Z89V57',
};
export default config;
