import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PdfSectionInitialValue, pdfValidationSchema } from './util';
import { PdfInfoType } from './type';
import { addPdf, deletePdf, getPdfList } from '../../database/datahelper';
import { toast } from 'react-toastify';

export const usePdfSection = () => {
  const [openAddPdfDrawer, setOpenAddPdfDrawer] = useState<boolean>(false);
  const [selectedPdf, setSelectedPdf] = useState<PdfInfoType | undefined>(undefined);
  const [pdfList, setPdfList] = useState<PdfInfoType[]>([]);
  const [selectedPdfForDelete, setSelectedPdfForDelete] = useState<string | undefined>(undefined);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setLoading(true);
    getPdfList()
      .then((res) => setPdfList(res))
      .catch(() => {
        toast.error('Something went wrong');
        setPdfList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    if (!selectedPdfForDelete) {
      return;
    }
    setDeleteLoading(true);
    deletePdf(selectedPdfForDelete)
      .then(() => {
        setSelectedPdfForDelete(undefined);
        fetchList();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  return {
    openAddPdfDrawer,
    setOpenAddPdfDrawer,
    selectedPdf,
    setSelectedPdf,
    refreshList: fetchList,
    pdfList,
    setSelectedPdfForDelete,
    selectedPdfForDelete,
    deleteLoading,
    handleDelete,
    loading,
  };
};

export const useAddPdfDrawer = (
  pdfInfo?: PdfInfoType,
  refreshList?: () => void,
  handleClose?: () => void,
) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: PdfSectionInitialValue(pdfInfo),
    resolver: yupResolver(pdfValidationSchema as any),
  });
  const [loading, setLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState('form');

  const onSubmit = (values: PdfInfoType) => {
    setLoading(true);
    // if (selectedQuestion) {
    //   updateQuestion(values)
    //     .then(() => {
    //       refreshList && refreshList();
    //       handleClose && handleClose();
    //     })
    //     .catch((err) => {
    //       toast.error(err);
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
    //   return;
    // }
    addPdf(values)
      .then(() => {
        refreshList && refreshList();
        handleClose && handleClose();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    errors,
    selectedTab,
    setSelectedTab,
    loading,
    onSubmit,
  };
};
