import { GridCellParams } from '@mui/x-data-grid';
import { Text } from '../../../../common/text/TextStyle';
import { AskDoubtQuestionListType } from '../../../myquestionlist/type';
import { format } from 'date-fns';
import { BsCheck } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import CustomDataGridAction from '../../../../common/CustomDataGridAction';

export const questionListColumn = (handleEdit: (list: AskDoubtQuestionListType) => void) => [
  {
    field: 'adminHeading',
    headerName: 'Heading',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<AskDoubtQuestionListType>) => (
      <Text>{params.row.title}</Text>
    ),
  },
  {
    field: 'isAnswered',
    headerName: 'IS Answered',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<AskDoubtQuestionListType>) => (
      <Text>{params.row.answer ? <BsCheck /> : <MdClose />}</Text>
    ),
  },
  {
    field: 'created',
    headerName: 'Created At',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<AskDoubtQuestionListType>) => (
      <Text>{params.row.createdAt && format(new Date(params.row.createdAt), 'dd MMM yyyy')}</Text>
    ),
  },
  {
    field: 'id',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<AskDoubtQuestionListType>) => (
      <CustomDataGridAction onEditClick={() => handleEdit(params.row)} />
    ),
  },
];
