import { FC, Suspense } from 'react';
import { useUserInfo } from '../../store/auth/selector';
import { useAppSelector } from '../../store/hook';
import Login from '../login/Login';
import Sidenavbar from '../sidenavbar/Sidenavbar';

type Props = {
  children: JSX.Element;
};

const PrivateRoute: FC<Props> = ({ children }) => {
  const userInfo = useAppSelector(useUserInfo);

  if (!userInfo) {
    return (
      <Suspense fallback={'..'}>
        <Login />
      </Suspense>
    );
  }

  return <Sidenavbar>{children}</Sidenavbar>;
};

export default PrivateRoute;
