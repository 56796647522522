import { useEffect, useState } from 'react';
import { PdfInfoType } from '../pdfs/type';
import { getPdfList } from '../../database/datahelper';
import { toast } from 'react-toastify';

export const usePdfList = () => {
  const [pdfList, setPdfList] = useState<PdfInfoType[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState('');

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setLoading(true);
    getPdfList()
      .then((res) => setPdfList(res))
      .catch(() => {
        toast.error('Something went wrong');
        setPdfList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    pdfList,
    loading,
    setSearchedText,
    searchedText,
  };
};
