import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { STUDENT_AUTH, StudentUserData } from './type';
import { studentLogin } from './api';

const initialState: StudentUserData = {
  expiryToke: '',
  refreshToken: '',
  token: '',
  userInfo: null,
  error: null,
  loading: false,
  success: false,
};

const studentAuthSlice = createSlice({
  name: STUDENT_AUTH,
  initialState,
  reducers: {
    updateAccessToken(state, accessToken: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.token = accessToken.payload;
      return stateClone;
    },
    updateUserDetail(state, userInfo: PayloadAction<StudentUserData>) {
      let stateClone = cloneDeep(state);
      stateClone = userInfo.payload;
      return stateClone;
    },
    clearStudentInfo(state) {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [studentLogin.pending as any]: (state: { loading: boolean; error: null }) => {
      state.loading = true;
      state.error = null;
    },
    [studentLogin.fulfilled as any]: (
      state: {
        loading: boolean;
        success: boolean;
        error: null;
        expiryToke: any;
        userInfo: any;
        token: any;
        refreshToken: any;
      },
      { payload }: any,
    ) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.expiryToke = payload.expiryToke;
      state.userInfo = payload.userInfo;
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
    },
    [studentLogin.rejected as any]: (state: { loading: boolean; error: any }, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    },
  } as any,
});

export const { clearStudentInfo, updateAccessToken, updateUserDetail } = studentAuthSlice.actions;
export default studentAuthSlice.reducer;
