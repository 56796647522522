import { Helmet } from 'react-helmet';
import { HomeBackgroundImageContainer } from './style';

const HomepageHeader = () => {
  return (
    <>
      <Helmet>
        <title>Doubt Jee</title>
      </Helmet>
      <HomeBackgroundImageContainer
        pt={['42px', '42px', '160px']}
        bgimage={'assets/study_image.jpg'}
      />
    </>
  );
};

export default HomepageHeader;
