import React from 'react';
import { PdfInfoType } from '../type';
import DataGridWrapper from '../../../common/DatagridWrapper';
import { pdfListColumn } from './ListColumn';

type PdfListProps = {
  list: PdfInfoType[];
  handleEdit: (question: PdfInfoType) => void;
  handleDelete: (questionId: string) => void;
  loading: boolean;
};

const PdfDatagrid = ({ handleDelete, handleEdit, list, loading }: PdfListProps) => {
  return (
    <DataGridWrapper
      rows={list}
      loading={loading}
      columns={pdfListColumn(handleEdit, handleDelete, (url) => window.open(url, '_bank'))}
    />
  );
};

export default PdfDatagrid;
