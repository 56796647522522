import { AUTH, IUserData } from "./type";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { userLogin } from "./api";

const initialState: IUserData = {
  expiryToke: "",
  refreshToken: "",
  token: "",
  userInfo: null,
  error: null,
  loading: false,
  success: false,
};

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    updateAccessToken(state, accessToken: PayloadAction<string>) {
      const stateClone = cloneDeep(state);
      stateClone.token = accessToken.payload;
      return stateClone;
    },
    updateUserDetail(state, userInfo: PayloadAction<IUserData>) {
      let stateClone = cloneDeep(state);
      console.log("userInfo", userInfo);
      stateClone = userInfo.payload;
      return stateClone;
    },
    clearUser(state) {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [userLogin.pending as any]: (state: { loading: boolean; error: null; }) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled as any]: (state: { loading: boolean; success: boolean; error: null; expiryToke: any; userInfo: any; token: any; refreshToken: any; }, { payload }: any) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.expiryToke = payload.expiryToke;
      state.userInfo = payload.userInfo;
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
    },
    [userLogin.rejected as any]: (state: { loading: boolean; error: any; }, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    },
  } as any,
});

export const { clearUser, updateAccessToken, updateUserDetail } =
  authSlice.actions;
export default authSlice.reducer;
