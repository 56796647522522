import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { loginInitialValue, loginValidationSchema } from '../../login/util';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePressEnterHook } from '../../../common/hook';
import { useAppDispatch } from '../../../store/hook';
import { LoginType } from '../type';
import { studentLogin } from '../../../store/userauth/api';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { Text } from '../../../common/text/TextStyle';
import CustomTextInput from '../../../common/field/CustomTextfield';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { ColorTheme } from '../../../theme/color';
import { PrimaryButton } from '../../../common/Button';
import { toast } from 'react-toastify';

type StudentLoginFormProps = {
  changeForm: (formType: string) => void;
  handleClose: () => void;
};
const StudentLoginForm = ({ changeForm, handleClose }: StudentLoginFormProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitialValue,
    resolver: yupResolver(loginValidationSchema),
  });
  const onSubmit = async (values: LoginType) => {
    setLoading(true);
    dispatch(studentLogin(values))
      .then((res: any) => {
        if (!res.error) {
          toast.success('Login successfull', {
            position: 'top-center',
          });
          handleClose();
          return;
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  usePressEnterHook(handleSubmit(onSubmit));
  return (
    <Grid container spacing={'20px'}>
      <Grid item xs={12} mt={'20px'}>
        <CustomTextInput
          register={register}
          name={'email'}
          fullWidth
          label={'Email'}
          errors={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomTextInput
          fullWidth
          register={register}
          type={showPassword ? 'text' : 'password'}
          name={'password'}
          label={'Password'}
          errors={errors}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  {!showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {error && (
        <Grid item xs={12} textAlign={'center'}>
          <Text size='sm' textcolor={ColorTheme.system.error[400]}>
            {error}
          </Text>
        </Grid>
      )}
      <Grid item xs={12}>
        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          fullWidth
          isLoading={loading}
          title={'Login'}
        />
      </Grid>
      <Grid display={'flex'} justifyContent={'space-between'} item xs={12}>
        <Text textweight={'regular'} size='sm'>
          <span
            style={{ fontWeight: 800, cursor: 'pointer' }}
            onClick={() => changeForm('forgotPassword')}
          >
            {' '}
            Forgot Password?
          </span>
        </Text>
        <Text textweight={'regular'} size='sm'>
          Don't have an Account?{' '}
          <span
            style={{ fontWeight: 800, cursor: 'pointer' }}
            onClick={() => changeForm('registerForm')}
          >
            {' '}
            Register
          </span>
        </Text>
      </Grid>
    </Grid>
  );
};

export default StudentLoginForm;
