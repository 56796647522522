import React from 'react';
import DataGridWrapper from '../../../common/DatagridWrapper';
import { PdfInfoType } from '../../pdfs/type';
import { pdfListColumn } from './ListColumn';

type PdfListProps = {
  list: PdfInfoType[];
  loading: boolean;
};
const PdfList = ({ list, loading }: PdfListProps) => {
  return (
    <DataGridWrapper
      rows={list}
      loading={loading}
      getRowHeight={() => 'auto'}
      columns={pdfListColumn((url) => window.open(url, '_bank'))}
    />
  );
};

export default PdfList;
