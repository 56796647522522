import { Typography } from '@mui/material';
import { useState } from 'react';
import Marquee from 'react-fast-marquee';
import BannerModal from './BannerModal';

type marqueeProps = {
  details: string;
  linkText: string;
};

const StyledMarquee = ({ details, linkText }: marqueeProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      <Marquee pauseOnHover={true} gradientColor='white' gradientWidth={'1000px'}>
        <Typography p={'10px'} color={'#fff'} variant='body2' fontWeight={'bold'}>
          {details}{' '}
          <span
            style={{ color: '#efefef', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setOpenModal(true)}
          >
            {linkText}
          </span>
        </Typography>
      </Marquee>
      {openModal && <BannerModal handleClose={() => setOpenModal(false)} />}
    </>
  );
};

export default StyledMarquee;
