import { FC } from 'react';
// import { questionListColumn } from './ListColumn';
import { AskDoubtQuestionListType } from '../../../myquestionlist/type';
import DataGridWrapper from '../../../../common/DatagridWrapper';
import { questionListColumn } from './ListColumn';

type AskDoubtQuestionListProps = {
  list: AskDoubtQuestionListType[];
  handleEdit: (question: AskDoubtQuestionListType) => void;
};
const AskDoubtQuestionList: FC<AskDoubtQuestionListProps> = ({ list, handleEdit }) => {
  return (
    <>
      <DataGridWrapper rows={list} columns={questionListColumn(handleEdit)} />
    </>
  );
};

export default AskDoubtQuestionList;
