import { FC } from "react";
import { Dialog, Divider, Grid, IconButton } from "@mui/material";
// import { Header3 } from "./Text";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TEXT_COLOR_THEME } from "../theme/color";
import { PrimaryButton, PrimaryTextButton } from "./Button";
import { Text } from "./text/TextStyle";

type Props = {
  handleClose: () => void;
  description?: string;
  modalTitle?: string;
  handleSubmit: () => void;
  isLoading?: boolean;
  isOpen: boolean;
};
const DeleteConfirmation: FC<Props> = ({
  handleClose,
  description,
  modalTitle,
  handleSubmit,
  isLoading,
  isOpen,
}) => (
  <Dialog open={isOpen} maxWidth={"sm"} onClose={handleClose}>
    <Grid container py={"10px"} px={"30px"}>
      <Grid item xs={12}>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Text size="lg" textweight="regular">{modalTitle}</Text>
          <IconButton onClick={handleClose}>
            <AiOutlineCloseCircle color={TEXT_COLOR_THEME.dark} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={"10px"}>
        <Divider />
      </Grid>
      <Grid item xs={12} mt={"16px"}>
        {description}
      </Grid>
      <Grid container spacing={"20px"} mt={"10px"} justifyContent={"flex-end"}>
        <Grid item>
          <PrimaryTextButton
            style={{
              borderRadius: 10,
              width: 134,
            }}
            onClick={handleClose}
            title={"Close"}
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            style={{
              borderRadius: 10,
              width: 134,
            }}
            onClick={(e) => {
              handleSubmit && handleSubmit();
              e.preventDefault();
            }}
            isLoading={isLoading}
            title={"Delete"}
          />
        </Grid>
      </Grid>
    </Grid>
  </Dialog>
);

export default DeleteConfirmation;
