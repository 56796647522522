import { Grid, IconButton } from '@mui/material';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { LogoStyle, SideBarContainer } from '../style';
import { sideMenuOptions } from '../util';
import MenuOption from './MenuOption';
import { AiOutlineLeftCircle, AiOutlineRightCircle } from 'react-icons/ai';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { Text } from '../../../common/text/TextStyle';

type Props = {
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
  isSideBarOpen: boolean;
  navigate: (to: string) => void;
};
const Sidebar: FC<Props> = ({ isSideBarOpen, setIsSideBarOpen, navigate }) => {
  const location = useLocation();
  return (
    <SideBarContainer justifyContent={'space-between'} display={'flex'} flexDirection={'column'}>
      <Grid>
        {isSideBarOpen ? (
          <Text size='xl' textweight='bold' textAlign={'center'}>
            <LogoStyle>
              <span style={{ fontWeight: 'bold', color: TEXT_COLOR_THEME.dark }}>DOUBT</span>{' '}
              <span style={{ fontWeight: 300 }}>JEE</span>
            </LogoStyle>
          </Text>
        ) : (
          <Text size='xl' textweight='bold' textAlign={'center'}>
            <LogoStyle>
              <span style={{ fontWeight: 'bold', color: TEXT_COLOR_THEME.dark }}>D</span>{' '}
              <span style={{ fontWeight: 300 }}>J</span>
            </LogoStyle>
          </Text>
        )}
        <Grid mt={'24px'} container spacing={'7px'}>
          {sideMenuOptions.map((item) => (
            <Grid key={item.id} item xs={12}>
              <MenuOption
                navigate={navigate}
                isSideBarOpen={isSideBarOpen}
                isactive={location.pathname.includes(item.url)}
                menuInfo={item}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid>
        <Grid container justifyContent={'center'}>
          <IconButton
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
          >
            {isSideBarOpen ? (
              <AiOutlineLeftCircle fontSize={'30px'} />
            ) : (
              <AiOutlineRightCircle fontSize={'30px'} />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </SideBarContainer>
  );
};

export default Sidebar;
