import Styled from "@emotion/styled";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import {
  HEADER_COLOR,
  SIDEBAR_COLOR,
  TEXT_COLOR_THEME,
} from "../../theme/color";
import { FONT_THEME } from "../../theme/fontTheme";
import { Text } from "../../common/text/TextStyle";
// import { Header2 } from "../../component/common/Text";

export const SideBarContainer = Styled(Box)`
    background: ${SIDEBAR_COLOR.menuBackground};
    border-radius: 14px;
    height: calc(100vh - 95px);
    overflow: auto;
    padding: 30px 10px;
`;

type MenuProps = {
  isactive: number;
  issidebaropen?: number;
};
export const StyledMenuOption = Styled(Grid)<MenuProps>`
    background: ${(props) =>
      props.isactive
        ? SIDEBAR_COLOR.isActiveOptionBackground
        : SIDEBAR_COLOR.inActiveOptionBackground} ;
    box-shadow: ${(props) =>
      props.isactive ? "0px 0px 20px #A387E2" : "none"};
    border-radius: 8px;
    padding: 14px 25px;
    cursor: pointer;
    ${(props) =>
      !props.issidebaropen &&
      `
        display: flex;
        justify-content: center;
    `}
`;

export const StyledMenuText = Styled(Typography)<MenuProps>`
    font-family: ${FONT_THEME.secondary};
    font-style: normal;
    font-weight: ${(props) => (props.isactive ? 700 : 600)};
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: ${(props) =>
      props.isactive
        ? SIDEBAR_COLOR.isActiveTextCOlor
        : SIDEBAR_COLOR.inActiveTextColor}; 
    svg {
        height: 24px;
        width: 24px;
    }
`;

export const LogoStyle = Styled.span`
  font-family: 'DM Sans', sans-serif;
  color: ${TEXT_COLOR_THEME.primary};
`;

export const HeaderContainer = Styled(Grid)`
    background: ${HEADER_COLOR.background};
    border-radius: 10px;
`;

export const StyledAvatar = Styled(Avatar)`
    cursor: pointer;
    &:hover {
        box-shadow: 1px 1px 10px grey;
    }
`;

export const OptionButton = Styled(Text)`
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
`;
