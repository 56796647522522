import Styled from '@emotion/styled';
import { Box } from '@mui/material';

export const StyledAnswerSection = Styled(Box)`
    
    img {
        max-width: 100%;
    }
    @media only screen and (max-width: 600px) {
  
    img {
        width: 100%;
    }
  
}
`;
