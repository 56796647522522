import React from 'react';
import { QuestionInfoType } from '../../dashboard/type';
import { Grid } from '@mui/material';
import { StyledAnswerSection } from '../Style';
import { HeadingText } from '../../../common/text/TextStyle';

type QuestionAnswerViewProps = {
  question: QuestionInfoType;
};
const QuestionAnswerView = ({ question }: QuestionAnswerViewProps) => {
  return (
    <Grid container mt={'10px'}>
      <Grid
        item
        xs={12}
        p={'10px'}
        border={`1px #efefef solid`}
        borderRadius={'10px'}
        minHeight={'500px'}
      >
        <HeadingText size='sm' textweight='bold'>
          {question.participantHeading}
        </HeadingText>
        <StyledAnswerSection
          dangerouslySetInnerHTML={{
            __html: question.question,
          }}
        />
        <StyledAnswerSection
          mt={'20px'}
          dangerouslySetInnerHTML={{
            __html: question.answer,
          }}
        />
        {question.pdfUrl && (
          <div
            style={{
              height: '76vh',
              marginTop: 20,
            }}
          >
            <iframe
              src={question.pdfUrl}
              width={'100%'}
              height={'100%'}
              allow='autoplay'
              title='Pdf viewer'
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default QuestionAnswerView;
