import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { StyledTextEditor } from './Style';
import { Control, Controller } from 'react-hook-form';
import { FC } from 'react';
import { Text } from '../text/TextStyle';
import { ColorTheme, TEXT_COLOR_THEME } from '../../theme/color';
import { FormHelperText } from '@mui/material';

type IEditorProps = {
  basicControl?: boolean;
  control: Control<any>;
  className?: string;
  showFontSizeControl?: boolean;
  isSimple?: boolean;
  id?: string;
  name: string;
  minHeight?: string;
  label: string;
  isMandatory?: boolean;
  descriptionText?: string;
};
const TextEditor: FC<IEditorProps> = ({
  basicControl,
  showFontSizeControl,
  isSimple,
  id,
  name,
  control,
  className,
  minHeight,
  label,
  isMandatory,
  descriptionText,
}) => {
  const getValue = (value: string) => {
    if (value.substr(value.length - 11) === '<p><br></p>') {
      return (value = value.substr(0, value.length - 11) + '<p>&#8205;</p>');
    }
    return value;
  };
  return (
    <StyledTextEditor minHeight={minHeight}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <Text
              size='sm'
              textcolor={fieldState.error ? ColorTheme.system.error[400] : ''}
              textweight='regular'
            >
              {label}
              {isMandatory && <sup style={{ color: TEXT_COLOR_THEME.error }}>*</sup>}
            </Text>
            {descriptionText && (
              <Text
                size='xs'
                textcolor='grey'
                display={'flex'}
                alignItems={'center'}
                textweight='regular'
              >
                {descriptionText}
              </Text>
            )}
            <ReactQuill
              value={field.value && getValue(field.value)}
              id={id}
              className={className}
              onChange={field.onChange}
              modules={
                isSimple
                  ? {
                      toolbar: [],
                    }
                  : basicControl
                  ? {
                      toolbar: [
                        [
                          showFontSizeControl && { header: [1, 2, false] },
                          'bold',
                          'italic',
                          'underline',
                        ],
                        [{ align: [] }],
                        [{ color: [] }],
                      ],
                    }
                  : {
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ align: [] }],
                        ['image'],
                        ['video'],
                        [{ color: [] }],
                      ],
                    }
              }
              formats={
                basicControl
                  ? ['header', 'bold', 'italic', 'underline', 'color', 'align']
                  : [
                      'header',
                      'bold',
                      'italic',
                      'underline',
                      'list',
                      'bullet',
                      'align',
                      'indent',
                      'image',
                      'video',
                      'color',
                    ]
              }
            />
            {fieldState.error && (
              <FormHelperText style={{ color: TEXT_COLOR_THEME.error }}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </StyledTextEditor>
  );
};
export default TextEditor;
