import { Box, Grid } from '@mui/material';
import { StyledCard } from '../../common/Card';
import { Text } from '../../common/text/TextStyle';
import QuestionList from './component/QuestionList';
import QuestionDetailDrawer from './component/QuestionDetailDrawer';
import { useDashboard } from './hook';
import { PrimaryButton } from '../../common/Button';
import DeleteConfirmation from '../../common/DeleteConfirmation';

const Dashboard = () => {
  const {
    openAddQuestionDrawer,
    refreshList,
    selectedQuestion,
    setOpenAddQuestionDrawer,
    setSelectedQuestion,
    setSelectedQuestionForDelete,
    questionList,
    selectedQuestionForDelete,
    deleteLoading,
    handleDelete,
  } = useDashboard();
  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledCard>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Text size='md' textweight='regular'>
              Question List
            </Text>
            <Box width={'150px'}>
              <PrimaryButton onClick={() => setOpenAddQuestionDrawer(true)} title='Add Question' />
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12} mt={'20px'}>
              <QuestionList
                handleDelete={setSelectedQuestionForDelete}
                handleEdit={setSelectedQuestion}
                list={questionList}
              />
            </Grid>
          </Grid>
          {(selectedQuestion || openAddQuestionDrawer) && (
            <QuestionDetailDrawer
              handleClose={() => {
                setSelectedQuestion(undefined);
                setOpenAddQuestionDrawer(false);
              }}
              selectedQuestion={selectedQuestion}
              refreshList={() => {
                refreshList();
              }}
            />
          )}
          {selectedQuestionForDelete && (
            <DeleteConfirmation
              isLoading={deleteLoading}
              isOpen
              description='Are you sure you want to delete?'
              modalTitle='Question Delete'
              handleClose={() => setSelectedQuestionForDelete(undefined)}
              handleSubmit={() => handleDelete()}
            />
          )}
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
