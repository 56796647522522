import { Box, Grid } from '@mui/material';
import { StyledCard } from '../../../common/Card';
import { Text } from '../../../common/text/TextStyle';
import { useAskDoubtAdmin } from './hook';
import AskDoubtQuestionList from './component/AskDoubtQuestionList';
import AskDoubtQuestionDetailDrawer from './component/AskDoubtQuestionDetailDrawer';

const AskDoubtAdmin = () => {
  const { questionList, refreshList, selectedQuestion, setSelectedQuestion } = useAskDoubtAdmin();
  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledCard>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Text size='md' textweight='regular'>
              Ask Doubt Question List
            </Text>
          </Box>
          <Grid container>
            <Grid item xs={12} mt={'20px'}>
              <AskDoubtQuestionList handleEdit={setSelectedQuestion} list={questionList} />
            </Grid>
          </Grid>
          {selectedQuestion && (
            <AskDoubtQuestionDetailDrawer
              handleClose={() => {
                setSelectedQuestion(undefined);
              }}
              questionInfo={selectedQuestion}
              refreshList={() => {
                refreshList();
              }}
            />
          )}
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default AskDoubtAdmin;
