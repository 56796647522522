import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetWindowSize } from '../../common/hook';
import { _url, pathIncludesNavbarStyling } from '../../util/SiteUtil';
import { IoMdClose } from 'react-icons/io';
import { ColorTheme } from '../../theme/color';
import { Text } from '../../common/text/TextStyle';
import { IoMdMenu } from 'react-icons/io';
import { SecondaryDefaultButton } from './Style';
import AccountButton from './AccountButton';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { useStudentUserInfo } from '../../store/userauth/selector';
import { studentLogout } from '../../store/userauth/api';
import LoginModal from './LoginModal';
import { clearStudentInfo } from '../../store/userauth';
import { routeUrl } from '../route/url';
import { toast } from 'react-toastify';
import MobileUserInfo from './MobileUserInfo';
import { useAppModalStatus } from '../../store/app/selector';
import { updateShowLoginModalStatus } from '../../store/app';

const navItems = [
  {
    label: 'Ask Doubts',
    type: 'internal',
    url: '/askdoubt',
    checkForStudentStatus: true,
  },
  {
    label: 'Questions',
    type: 'internal',
    url: '/questions?type=all',
  },
  {
    label: 'Contact Us',
    type: 'internal',
    url: '/contactus',
  },
  {
    label: 'About Us',
    type: 'internal',
    url: '/aboutus',
  },

  {
    label: 'How it works',
    type: 'internal',
    url: '/how-it-works',
  },
];

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const loginModalStatus = useAppSelector(useAppModalStatus);
  const location = useLocation();
  const studentInfo = useAppSelector(useStudentUserInfo);

  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  const dispatch = useAppDispatch();
  const { mobileView } = useGetWindowSize();
  const listenScrollEvent = () => {
    if (window.scrollY < 73) {
      return setIsScrolled(false);
    } else if (window.scrollY > 70) {
      return setIsScrolled(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const drawer = () => (
    <Box sx={{ textAlign: 'center' }}>
      <Grid p={'16px'} flexWrap={'nowrap'} container justifyContent={'space-between'}>
        <img
          alt='logo'
          width='40px'
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer', borderRadius: '50%' }}
          src={_url('assets/logo.png')}
        />
        <IconButton
          color='inherit'
          edge='start'
          onClick={() => {
            handleDrawerToggle();
          }}
          sx={{ display: { sm: 'none' } }}
        >
          <IoMdClose style={{ color: ColorTheme.base.black }} />
        </IconButton>
      </Grid>
      {studentInfo.token && (
        <MobileUserInfo
          auth={studentInfo}
          logoutUser={() => {
            dispatch(studentLogout())
              .then(() => {
                dispatch(clearStudentInfo());
                navigate(routeUrl.homescreen);
              })
              .catch((err) => {
                toast.error(err);
              });
          }}
        />
      )}
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText
                primary={item.label}
                onClick={() => {
                  if (item.type === 'external') {
                    window.location.href = item.url;
                    return;
                  }
                  navigate(item.url);
                  handleDrawerToggle();
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem style={{ justifyContent: 'center' }}>
          {!studentInfo.token ? (
            <Box mt={['28px', '28px', '0px']}>
              <SecondaryDefaultButton
                onClick={() => {
                  dispatch(updateShowLoginModalStatus(!loginModalStatus));
                }}
                style={{ width: '141px' }}
              >
                Login
              </SecondaryDefaultButton>
            </Box>
          ) : (
            <AccountButton
              logoutUser={() => {
                dispatch(studentLogout())
                  .then(() => {
                    dispatch(clearStudentInfo());
                    navigate(routeUrl.homescreen);
                  })
                  .catch((err) => {
                    toast.error(err);
                  });
              }}
              auth={studentInfo}
            />
          )}
        </ListItem>
      </List>
    </Box>
  );

  const path = window.location.pathname;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          sx={{
            background:
              mobileView || isScrolled || pathIncludesNavbarStyling(path) ? 'white' : 'inherit',
            boxShadow: 'none',
            position: 'fixed',
            top: '35px',
          }}
          component='nav'
        >
          <Toolbar>
            <Grid p={'10px'} flexWrap={'nowrap'} container justifyContent={'space-between'}>
              <img
                alt='logo'
                width='50px'
                onClick={() => navigate('/')}
                style={{ cursor: 'pointer', borderRadius: '50%' }}
                src={
                  mobileView || isScrolled || pathIncludesNavbarStyling(path)
                    ? _url('assets/logo.png')
                    : _url('assets/logo.png')
                }
              />
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                sx={{ display: { sm: 'none' } }}
              >
                <IoMdMenu style={{ color: ColorTheme.base.black }} />
              </IconButton>
              <Box alignItems={'center'} display={['none', 'none', 'flex']} gap={'24px'}>
                {navItems.map((item) => (
                  <Text
                    size={'md'}
                    textweight={'medium'}
                    style={{ cursor: 'pointer' }}
                    key={item.label}
                    onClick={() => {
                      if (item.type === 'external') {
                        window.location.href = item.url;
                        return;
                      }
                      if (item.checkForStudentStatus) {
                        if (!studentInfo.token) {
                          dispatch(updateShowLoginModalStatus(true));
                          return;
                        }
                      }
                      navigate(item.url);
                      handleDrawerToggle();
                    }}
                    textcolor={
                      isScrolled || pathIncludesNavbarStyling(path)
                        ? ColorTheme.base.black
                        : ColorTheme.base.white
                    }
                  >
                    {item.label}
                  </Text>
                ))}
                {!studentInfo.token ? (
                  <Box mt={['28px', '28px', '0px']}>
                    <SecondaryDefaultButton
                      onClick={() => {
                        dispatch(updateShowLoginModalStatus(!loginModalStatus));
                      }}
                      style={{ width: '141px' }}
                    >
                      Login
                    </SecondaryDefaultButton>
                  </Box>
                ) : (
                  <AccountButton
                    logoutUser={() => {
                      dispatch(studentLogout())
                        .then(() => {
                          dispatch(clearStudentInfo());
                          navigate(routeUrl.homescreen);
                        })
                        .catch((err) => {
                          toast.error(err);
                        });
                    }}
                    auth={studentInfo}
                  />
                )}
                <LoginModal
                  toggleLoginModal={() => dispatch(updateShowLoginModalStatus(!loginModalStatus))}
                  isOpen={loginModalStatus}
                />
              </Box>
            </Grid>
          </Toolbar>
        </AppBar>
        {mobileView && (
          <nav>
            <Drawer
              variant='temporary'
              open={mobileOpen}
              anchor='top'
              onClose={handleDrawerToggle}
              PaperProps={{
                sx: {
                  top: '40px',
                },
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box' },
              }}
            >
              {drawer()}
            </Drawer>
          </nav>
        )}
      </Box>
    </>
  );
};

export default Header;
