import Styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { LOGIN_COLOR_THEME } from '../../theme/color';

export const LoginContainer = Styled(Grid)`
    background: rgb(0,0,0);
    background: linear-gradient(103deg, ${LOGIN_COLOR_THEME.primary} 50%, rgba(245,245,245,1) 50%);
`;

export const LoginFormContainer = Styled(Grid)`
    border-radius: 20px;
    padding: 40px;
    background: ${LOGIN_COLOR_THEME.light};
    box-shadow: 1px 1px 10px ${LOGIN_COLOR_THEME.dark};
`;
