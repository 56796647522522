import { GridCellParams } from '@mui/x-data-grid';
import { PdfInfoType } from '../type';
import { Text } from '../../../common/text/TextStyle';
import { format } from 'date-fns';
import CustomDataGridAction from '../../../common/CustomDataGridAction';
import { Box, Chip } from '@mui/material';
import { QuestionTypeOptions } from '../../../constant/OptConstant';

export const pdfListColumn = (
  handleEdit: (list: PdfInfoType) => void,
  handleDelete: (questionId: string) => void,
  handleViewClick: (url: string) => void,
) => [
  {
    field: 'adminHeading',
    headerName: 'Heading',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<PdfInfoType>) => <Text>{params.row.pdfText}</Text>,
  },
  {
    field: 'tag',
    headerName: 'Tags',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<PdfInfoType>) => (
      <Box gap={'2px'} display={'flex'}>
        {params.row.tag.map((item) => (
          <Chip key={item} label={QuestionTypeOptions.find((opt) => opt.value === item)?.label} />
        ))}
      </Box>
    ),
  },
  {
    field: 'created',
    headerName: 'Created At',
    flex: 1,
    sortable: false,
    renderCell: (params: GridCellParams<PdfInfoType>) => (
      <Text>{params.row.createdAt && format(new Date(params.row.createdAt), 'dd MMM yyyy')}</Text>
    ),
  },
  {
    field: 'id',
    headerName: '',
    width: 100,
    sortable: false,
    renderCell: (params: GridCellParams<PdfInfoType>) => (
      <CustomDataGridAction
        onDeleteClick={() => handleDelete(params.row.id)}
        onEditClick={() => handleEdit(params.row)}
        onViewClick={() => handleViewClick(params.row.pdfUrl)}
      />
    ),
  },
];
