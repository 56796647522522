/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import { useRef } from 'react';
import {
  EMAILJS_PUBLIC_KEY,
  EMAILJS_SERVER_KEY,
  EMAILJS_TEMPLATE_KEY,
} from '../../constant/Constant';
import { ContactUsType } from './type';
import { ContactUsValidation, contactUsInitialValue } from './util';

export const useContactUs = () => {
  const form: any = useRef();
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: contactUsInitialValue,
    resolver: yupResolver(ContactUsValidation),
  });

  const onSubmit = (values: ContactUsType) => {
    emailjs
      .sendForm(EMAILJS_SERVER_KEY, EMAILJS_TEMPLATE_KEY, form.current, EMAILJS_PUBLIC_KEY)
      .then(
        () => {
          toast.success('Message send succesfully');
          reset();
        },
        (error) => {
          toast.error(error.text);
        },
      );
  };
  return {
    handleSubmit,
    register,
    control,
    errors,
    onSubmit,
    form,
  };
};
