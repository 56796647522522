import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { askDoubtQuestionInitialValue, askDoubtValidationSchema } from './util';
import { yupResolver } from '@hookform/resolvers/yup';
import { AskDoubtQuestionType } from './type';
import { postStudentAskQuestion } from '../../database/datahelper';
import { useAppSelector } from '../../store/hook';
import { useStudentUserDetails } from '../../store/userauth/selector';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const useAskDoubt = () => {
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useAppSelector(useStudentUserDetails);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: askDoubtQuestionInitialValue(),
    resolver: yupResolver(askDoubtValidationSchema as any),
  });

  const subjectTags = useWatch({
    control,
    name: 'subjectTags',
  });
  const questionCategory = useWatch({
    control,
    name: 'questionCategory',
  });

  const postQuestion = (value: AskDoubtQuestionType) => {
    if (userInfo) {
      setIsLoading(true);

      postStudentAskQuestion(value, userInfo.userId)
        .then(() => {
          toast.success('Answer Posted successfully');
          navigate('/');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong. Please try again');
        });
    }
  };
  return {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    errors,
    subjectTags,
    questionCategory,
    postQuestion,
    isLoading,
  };
};
