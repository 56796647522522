import { Grid } from '@mui/material';
import { useState } from 'react';
import { Text } from '../../../common/text/TextStyle';
import CustomTextInput from '../../../common/field/CustomTextfield';
import { ColorTheme } from '../../../theme/color';
import { PrimaryButton } from '../../../common/Button';
import { usePressEnterHook } from '../../../common/hook';
import { toast } from 'react-toastify';
import { sendPasswordResetEmail } from 'firebase/auth';
import { ForgotPasswordType } from '../type';
import { firebaseAuth } from '../../../firebase';
import { useForm } from 'react-hook-form';
import { forgotPasswordInitialValue, forgotPasswordValidationSchema } from '../util';
import { yupResolver } from '@hookform/resolvers/yup';

type StudentLoginFormProps = {
  changeForm: (formType: string) => void;
  handleClose: () => void;
};

const StudentForgotPassword = ({ changeForm, handleClose }: StudentLoginFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: forgotPasswordInitialValue,
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = (values: ForgotPasswordType) => {
    setLoading(true);
    sendPasswordResetEmail(firebaseAuth, values.email)
      .then(() => {
        toast.success('Please check your mail and reset your password');
      })
      .catch((err) => {
        toast.error(err.message);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  usePressEnterHook(handleSubmit(onSubmit));
  return (
    <Grid container spacing={'20px'}>
      <Grid item xs={12} mt={'20px'}>
        <CustomTextInput
          register={register}
          name={'email'}
          fullWidth
          label={'Email'}
          errors={errors}
        />
      </Grid>
      {error && (
        <Grid item xs={12} textAlign={'center'}>
          <Text size='sm' textcolor={ColorTheme.system.error[400]}>
            {error}
          </Text>
        </Grid>
      )}
      <Grid item xs={12}>
        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          fullWidth
          isLoading={loading}
          title={'Reset Password'}
        />
      </Grid>
      <Grid display={'flex'} justifyContent={'space-between'} item xs={12}>
        <Text textweight={'regular'} textsize='14px'>
          Go to
          <span
            style={{ fontWeight: 800, cursor: 'pointer' }}
            onClick={() => changeForm('loginForm')}
          >
            {' '}
            Login!
          </span>
        </Text>
      </Grid>
    </Grid>
  );
};

export default StudentForgotPassword;
