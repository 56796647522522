import { array, object, string } from 'yup';
import { TabOptionType } from '../../common/component/type';
import { QuestionInfoType } from './type';
import { LevelTagsOptions, SubjectOptions } from '../../constant/OptConstant';

export const questionInitialValue = (data?: QuestionInfoType): QuestionInfoType => ({
  adminHeading: data?.adminHeading || '',
  answer: data?.answer || '',
  createdAt: data?.createdAt || '',
  id: data?.id || '',
  participantHeading: data?.participantHeading || '',
  question: data?.question || '',
  updatedAt: data?.updatedAt || '',
  pdfUrl: data?.pdfUrl || '',
  questionLevel: data?.questionLevel
    ? LevelTagsOptions?.filter((item) => data.questionLevel.includes(item.value)).map(
        (item) => item.value,
      )
    : [],
  subjectTags: data?.questionLevel
    ? SubjectOptions?.filter((item) => data.questionLevel.includes(item.value)).map(
        (item) => item.value,
      )
    : [],
});

export const questionValidationSchema = object().shape({
  adminHeading: string().required('Please enter admin Heading'),
  question: string().required('Please enter question'),
  answer: string().required('Please enter Answer'),
  questionLevel: array().min(1, 'Please select any one level type'),
  subjectTags: array().min(1, 'Please select any one subject tag'),
});

export const QuestionTabOption: TabOptionType[] = [
  {
    label: 'Form',
    value: 'form',
  },
  {
    label: 'Preview',
    value: 'preview',
  },
];
