import { intervalToDuration } from 'date-fns';

function stringToColor(string: string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')?.[0]?.[0] || ''}${name.split(' ')?.[1]?.[0] || ''}`.toUpperCase(),
  };
};

export const generateUniqueId = () => {
  const timestamp = new Date().getTime();
  const uniqueId = `${timestamp}-${Math.random().toString(36).substr(2, 9)}`;
  return uniqueId;
};

export const formatDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const convertSecondsToHrsMin = (seconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });

  return {
    min: duration.minutes || 0,
    hrs: duration.hours || 0,
  };
};

export const convertHrsMinToSeconds = (time: { hrs: number; min: number }) => {
  return time.hrs * 60 * 60 + time.min * 60;
};

export const _url = (url: string) => `/${url}`;

export const pathIncludesNavbarStyling = (path: string) => {
  if (path === '/') {
    return false;
  }
  return true;
};

export const removeTags = (str: string) => {
  return str
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/\s+/g, ' ')
    .trim()
    .replace(/([\w\d]+)([.?!])?(\s|$)/g, '$1$2 ');
};
