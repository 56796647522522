import { Control, useWatch } from 'react-hook-form';
import { QuestionInfoType } from '../type';
import { Box, Grid } from '@mui/material';
import { HeadingText } from '../../../common/text/TextStyle';

type PreviewSecionProps = {
  control: Control<QuestionInfoType>;
};
const PreviewSecion = ({ control }: PreviewSecionProps) => {
  const particpantAnswerHeading = useWatch({
    control,
    name: 'participantHeading',
  });

  const question = useWatch({
    control,
    name: 'question',
  });

  const answer = useWatch({
    control,
    name: 'answer',
  });

  const pdfUrl = useWatch({
    control,
    name: 'pdfUrl',
  });
  return (
    <Grid container mt={'10px'}>
      <Grid
        item
        xs={12}
        p={'10px'}
        border={`1px #efefef solid`}
        borderRadius={'10px'}
        minHeight={'500px'}
      >
        <HeadingText size='sm' textweight='bold'>
          {particpantAnswerHeading}
        </HeadingText>
        <Box
          dangerouslySetInnerHTML={{
            __html: question,
          }}
        />
        <Box
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        />
        {pdfUrl && (
          <div
            style={{
              height: '76vh',
              marginTop: 20,
            }}
          >
            <iframe
              src={pdfUrl}
              width={'100%'}
              height={'100%'}
              allow='autoplay'
              title='Pdf viewer'
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default PreviewSecion;
