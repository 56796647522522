import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { PdfInfoType } from '../type';
import { Box, Grid } from '@mui/material';
import CustomTextInput from '../../../common/field/CustomTextfield';
import CustomMultiSelect from '../../../common/field/CustomMultiSelect';
import { QuestionTypeOptions } from '../../../constant/OptConstant';
import { PrimaryButton, PrimaryTextButton } from '../../../common/Button';

type PdfFormProps = {
  register: UseFormRegister<PdfInfoType>;
  control: Control<PdfInfoType>;
  loading: boolean;
  onSubmit: () => void;
  errors: FieldErrors<PdfInfoType>;
  handleClose: () => void;
};
const PDFForm = ({ control, handleClose, errors, loading, onSubmit, register }: PdfFormProps) => {
  return (
    <Grid container mt={'5px'} rowSpacing={'20px'}>
      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='pdfText'
          label={'PDF Text'}
          register={register}
          isMandatory
          fullWidth
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='pdfDescription'
          label={'PDF Description'}
          register={register}
          fullWidth
          multiline
          rows={4}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomMultiSelect
          variant='outlined'
          name='tag'
          textlabel={'PDF Tags'}
          control={control}
          size='small'
          isMandatory
          multiple
          fullWidth
          options={QuestionTypeOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='pdfUrl'
          label={'PDF Public URL'}
          infoText={
            'eg. Url: https://drive.google.com/file/d/1KL9x7RVeVph-WUL7EYW5_hijaIEW3G9m/preview'
          }
          register={register}
          isMandatory
          fullWidth
          errors={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Box sx={{ width: 150 }}>
            <PrimaryTextButton onClick={handleClose} title='Cancel' />
          </Box>
          <Box sx={{ width: 150 }}>
            <PrimaryButton onClick={onSubmit} isLoading={loading} title='Save' />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PDFForm;
