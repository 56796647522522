import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './auth';
import studentReducer from './userauth';
import appReducer from './app';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';

const persistConfig = {
  key: 'education/auth',
  storage,
};

const persistStudentConfig = {
  key: 'student/auth',
  storage,
};

const persistAppConfig = {
  key: 'app/constant',
  storage,
  blacklist: ['showLoginModal'],
};
const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedStudentReducer = persistReducer(persistStudentConfig, studentReducer);
const persistedAppReducer = persistReducer(persistAppConfig, appReducer);

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  studentAuth: persistedStudentReducer,
  appState: persistedAppReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
