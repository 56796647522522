import { AskDoubtQuestionListType } from './type';

export const filterAskQuestionList = (
  questionList: AskDoubtQuestionListType[],
  searchText: string,
): AskDoubtQuestionListType[] => {
  if (!searchText) {
    return questionList;
  }
  return questionList.filter((item) => item.title.toLowerCase().includes(searchText.toLowerCase()));
};
