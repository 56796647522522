import Styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { CARD_COLOR } from "../theme/color";

export const StyledCard = Styled(Grid)`
    border-radius: 5px;
    background: ${CARD_COLOR.primary};
    padding: 10px;
    box-shadow: 0px 12px 36px #E5EDF2;
`;
