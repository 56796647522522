import { Box } from '@mui/material';
import { FONT_THEME } from '../../theme/fontTheme';
import Styled from '@emotion/styled';
import { BUTTON_COLOR_THEME } from '../../theme/color';

export const StyledTabContainer = Styled(Box)`
 & .MuiButtonBase-root {
    font-family: ${FONT_THEME.secondary};
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
 };
 
 & .Mui-selected {
    color: ${BUTTON_COLOR_THEME.primary} !important;
    border-radius: 10px 0px 0px 10px;
 }

 & .MuiTabs-indicator {
    background-color: ${BUTTON_COLOR_THEME.primary};
 }

 & .MuiButtonBase-root {
    align-items: baseline;
 }

 & .MuiTabScrollButton-horizontal {
   align-items: center !important;
 }
`;

export const DropzoneContainer = Styled(Box)`
    border-radius: 20px;
    border: 1px dashed #9D9D9D;
    background: #FFF;
    cursor: pointer;
    width: 100%;
    padding: 30px 0px;
    justify-content: center;
    align-items: center;
`;

export const UploadFileContainer = Styled(Box)`
        height: 60px;
        width: 60px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: ${FONT_THEME.primary};
`;
