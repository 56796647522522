/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { QuestionInfoType } from '../dashboard/type';
import { getQuestionList } from '../../database/datahelper';
import { useNavigate } from 'react-router-dom';

export const useQuizView = () => {
  const [questionList, setQuestionList] = useState<QuestionInfoType[]>([]);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setLoading(true);
    setCurrentQuestionNumber(
      searchParams.get('questionno') ? Number(searchParams.get('questionno')) : 0,
    );
    getQuestionList()
      .then((res) => setQuestionList(res))
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    questionList,
    currentQuestionNumber,
    loading,
    setCurrentQuestionNumber,
    navigate,
  };
};
