import { TabOptionType } from '../../common/component/type';
import { QuestionInfoType } from '../dashboard/type';

export const UserQuestionTabOption: TabOptionType[] = [
  {
    label: 'Physics',
    value: 'physics',
  },
  {
    label: 'Chemistry',
    value: 'chem',
  },
  {
    label: 'Maths',
    value: 'maths',
  },
  {
    label: 'PDFs',
    value: 'pdf',
  },
];

export const UserOlympiadTabOption: TabOptionType[] = [
  ...UserQuestionTabOption,
  {
    label: 'Biology',
    value: 'biology',
  },
];

export const UserNeetTabOption: TabOptionType[] = [
  {
    label: 'Physics',
    value: 'physics',
  },
  {
    label: 'Chemistry',
    value: 'chem',
  },
  {
    label: 'Zoology',
    value: 'zoology',
  },
  {
    label: 'Botany',
    value: 'bootany',
  },
  {
    label: 'PDFs',
    value: 'pdf',
  },
];

export const UserAllTabOptions: TabOptionType[] = [
  {
    label: 'Physics',
    value: 'physics',
  },
  {
    label: 'Chemistry',
    value: 'chem',
  },
  {
    label: 'Maths',
    value: 'maths',
  },
  {
    label: 'Biology',
    value: 'biology',
  },
  {
    label: 'Zoology',
    value: 'zoology',
  },
  {
    label: 'Botany',
    value: 'bootany',
  },
  {
    label: 'PDFs',
    value: 'pdf',
  },
];

export const userTabOptions: Record<string, TabOptionType[]> = {
  olympiad: UserOlympiadTabOption,
  jeemains: UserQuestionTabOption,
  neet: UserNeetTabOption,
  jeeadvance: UserQuestionTabOption,
};

export const filterQuestionList = (
  questionList: QuestionInfoType[],
  levelType: string,
  questionType: string,
  searchText: string,
) => {
  const lowerCaseSearchText = searchText.toLowerCase();

  let transformRes = [];
  if (levelType === 'all') {
    transformRes = questionList.filter((item) => item.questionLevel.includes(questionType));
  } else {
    transformRes = questionList.filter(
      (que) =>
        que.questionLevel.includes(levelType as string) && que.questionLevel.includes(questionType),
    );
  }

  if (questionType === 'pdf') {
    transformRes = questionList.filter((que) => que.pdfUrl);
  }

  return transformRes.filter(
    (item) =>
      item.adminHeading.toLowerCase().includes(lowerCaseSearchText) ||
      item.questionLevel.includes(lowerCaseSearchText),
  );
};
