import { Grid, IconButton } from "@mui/material";
import { FC, ReactNode } from "react";
import { HeaderContainer, StyledAvatar } from "../style";
import { stringAvatar } from "../../../util/SiteUtil";
import { useHeader } from "../hook";
import ProfileModal from "./ProfileModal";
import { useAppSelector } from "../../../store/hook";
import { useUserInfo } from "../../../store/auth/selector";

type HeaderProps = {
  isSideBarOpen: boolean;
  children: ReactNode;
};
const Header: FC<HeaderProps> = ({ isSideBarOpen, children }) => {
  const { openProfilePopup, setOpenProfilePopup } = useHeader();
  const userInfo = useAppSelector(useUserInfo);
  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderContainer
          py={"10px"}
          px={"20px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Grid alignItems={"center"} gap={"5px"}>
            <IconButton onClick={(e) => setOpenProfilePopup(e.currentTarget)}>
              
                <StyledAvatar {...stringAvatar(userInfo?.email || "Admin")} />
              
            </IconButton>
          </Grid>
          {openProfilePopup && (
            <ProfileModal
              anchorEl={openProfilePopup}
              handleClose={() => setOpenProfilePopup(null)}
            />
          )}
        </HeaderContainer>
      </Grid>
      <Grid
        mt={"10px"}
        className={"content"}
        pr={"10px"}
        height={`calc(100vh - ${isSideBarOpen ? 160 : 110}px)`}
        overflow={"auto"}
        item
        xs={12}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default Header;
