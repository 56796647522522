import { Box, Grid } from '@mui/material';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { QuestionInfoType } from '../type';
import TextEditor from '../../../common/field/CustomQuillEditor';
import CustomTextInput from '../../../common/field/CustomTextfield';
import { PrimaryButton, PrimaryTextButton } from '../../../common/Button';
import CustomMultiSelect from '../../../common/field/CustomMultiSelect';
import { LevelTagsOptions, SubjectOptions } from '../../../constant/OptConstant';

type QuestionFormProps = {
  register: UseFormRegister<QuestionInfoType>;
  control: Control<QuestionInfoType>;
  loading: boolean;
  onSubmit: () => void;
  errors: FieldErrors<QuestionInfoType>;
};

const QuestionForm = ({ control, register, onSubmit, errors, loading }: QuestionFormProps) => {
  return (
    <Grid container mt={'5px'} rowSpacing={'20px'}>
      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='adminHeading'
          label={'Admin Heading'}
          register={register}
          isMandatory
          fullWidth
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomMultiSelect
          variant='outlined'
          name='questionLevel'
          textlabel={'Level Tags'}
          control={control}
          size='small'
          multiple
          isMandatory
          fullWidth
          options={LevelTagsOptions}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomMultiSelect
          variant='outlined'
          name='subjectTags'
          textlabel={'Subject Tags'}
          control={control}
          size='small'
          multiple
          isMandatory
          fullWidth
          options={SubjectOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='participantHeading'
          label={'Participant Heading'}
          register={register}
          isMandatory
          fullWidth
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <TextEditor
          isMandatory
          control={control}
          name='question'
          minHeight='30vh'
          label='Question Text'
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextInput
          variant='outlined'
          name='pdfUrl'
          label={'PDF Public URL'}
          infoText={
            'eg. Url: https://drive.google.com/file/d/1KL9x7RVeVph-WUL7EYW5_hijaIEW3G9m/preview'
          }
          register={register}
          isMandatory
          fullWidth
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <TextEditor control={control} name='answer' minHeight='30vh' label='Answer' />
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Box sx={{ width: 150 }}>
            <PrimaryTextButton title='Cancel' />
          </Box>
          <Box sx={{ width: 150 }}>
            <PrimaryButton onClick={onSubmit} isLoading={loading} title='Save' />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default QuestionForm;
