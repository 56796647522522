import { Box, Grid } from '@mui/material';
import { HeadingText, Text } from '../../../common/text/TextStyle';
import { OnlineCourseCardType } from './type';
import { ColorTheme } from '../../../theme/color';

type OnlineCourseCardProps = {
  cardInfo: OnlineCourseCardType;
  handleExploreClick: (url: string) => void;
};

const OnlineCourseCard = ({ cardInfo, handleExploreClick }: OnlineCourseCardProps) => {
  return (
    <Grid
      container
      boxShadow={'0 1px 3px rgba(3,0,71,.09)'}
      borderRadius={'10px'}
      px={'40px'}
      py={'20px'}
      bgcolor={ColorTheme.base.white}
    >
      <Grid item xs={12}>
        <HeadingText size='xs' textweight='bold'>
          {cardInfo.title}
        </HeadingText>
        <Text size='md' textweight='regular'>
          {cardInfo.descriptionText}
        </Text>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Text
            textcolor={ColorTheme.secondary[1000]}
            size='md'
            sx={{
              cursor: 'pointer',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => handleExploreClick(cardInfo.url)}
            textweight='regular'
          >
            {cardInfo.exploreText}
          </Text>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OnlineCourseCard;
