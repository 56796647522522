import { Button } from '@mui/material';
import { ColorTheme } from '../../theme/color';
import Styled from '@emotion/styled';

export const SecondaryDefaultButton = Styled(Button)`
   border-radius: 8px;
    border: 1px solid ${ColorTheme.grayNeutral[700]};
    background: ${ColorTheme.base.white};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    color: var(--gray-700, #384250);
    /* Text sm(14px)/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: none;
   border: 1px solid ${ColorTheme.primary[500]};
   background: ${ColorTheme.primary[500]};  
   color: ${ColorTheme.base.white};
   &:hover {
    background: ${ColorTheme.primary[500]};
   }
`;
