import { Grid } from '@mui/material';
import { HeadingText, Text } from '../../common/text/TextStyle';
import SearchableTextfield from '../../common/SearchableTextfield';
import LoadingWrapper from '../../common/component/LoadingWrapper';
import { useAskDoubtQuestion } from './hook';
import { filterAskQuestionList } from './util';
import AskDoubtQuestionCard from './component/AskDoubtQuestionCard';

const AskDoubtQuestionList = () => {
  const { questionList, loading, searchedText, setSearchedText } = useAskDoubtQuestion();
  const renderQuestionSection = () => {
    if (filterAskQuestionList(questionList, searchedText).length === 0) {
      return (
        <Grid container alignItems={'center'} height={'50vh'} justifyContent={'center'}>
          <Text size='lg' textweight='extraBold'>
            No Questions Found
          </Text>
        </Grid>
      );
    }
    return (
      <>
        {filterAskQuestionList(questionList, searchedText).map((que) => (
          <Grid item xs={12} key={que.questionId} mt={'30px'}>
            <AskDoubtQuestionCard questionInfo={que} />
          </Grid>
        ))}
      </>
    );
  };
  return (
    <Grid container justifyContent={'center'}>
      <Grid item mt={['120px', '120px', '0px']} xs={11} md={10}>
        <Grid container>
          <Grid item xs={12}>
            <HeadingText textheight='54px' size='sm' textweight='bold'>
              Asked Doubts List
            </HeadingText>
          </Grid>
          <Grid item xs={12} mt={'20px'}>
            <Grid container columnSpacing={'20px'} flexWrap={'wrap'} alignItems={'center'}>
              <Grid item xs={12} md={6} order={[1, 2, 2]}>
                <SearchableTextfield
                  value={searchedText}
                  onChange={(e) => setSearchedText(e.target.value)}
                  fullWidth
                  placeholder='Search'
                  sx={{
                    background: '#fff',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <LoadingWrapper loading={loading} height='50vh'>
            {renderQuestionSection()}
          </LoadingWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AskDoubtQuestionList;
