import { object, string } from 'yup';
import { ContactUsType } from './type';

export const contactUsInitialValue: ContactUsType = {
  email: '',
  message: '',
  name: '',
};

export const ContactUsValidation = object().shape({
  email: string().required('Enter Email'),
  message: string().required('Enter Message'),
  name: string().required('Enter Name'),
});
