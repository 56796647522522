/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { getQuestionList } from '../../database/datahelper';
import { QuestionInfoType } from '../dashboard/type';
import { coursesList } from '../homescreen/util';
import { useLocation, useParams } from 'react-router-dom';

export const useQuestionList = () => {
  const [questionList, setQuestionList] = useState<QuestionInfoType[]>([]);
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const type = searchParams.get('type') || 'all';
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('physics');
  const [searchedText, setSearchedText] = useState('');

  useEffect(() => {
    fetchList(type);
  }, [location]);

  const fetchList = (type: string) => {
    setLoading(true);
    getQuestionList()
      .then((res) => {
        if (type === 'all') {
          setQuestionList(res);
          return;
        }
        setQuestionList(res.filter((que) => que.questionLevel.includes(type as string)));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const courseName = useMemo(
    () => coursesList.find((course) => course.type === searchParams.get('type'))?.title || 'all',
    [coursesList, location],
  );

  return {
    questionList,
    setQuestionList,
    courseName,
    loading,
    setSelectedTab,
    selectedTab,
    levelType: type,
    setSearchedText,
    searchedText,
  };
};

export const useQuestionInfo = () => {
  const [loading, setLoading] = useState(false);
  const [questionInfo, setQuestionInfo] = useState<QuestionInfoType | null>(null);
  const { questionId } = useParams();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setLoading(true);
    getQuestionList()
      .then((res) => {
        const question = res.find((que) => que.id === questionId);
        question && setQuestionInfo(question);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    questionInfo,
    loading,
  };
};
