import { Drawer, Grid } from '@mui/material';
import { Text } from '../../../common/text/TextStyle';
import { QuestionInfoType } from '../type';
import { useQuestionDetailDrawer } from '../hook';
import CustomTab from '../../../common/component/CustomTab';
import { QuestionTabOption } from '../util';
import QuestionForm from './QuestionForm';
import PreviewSecion from './PreviewSecion';

type QuestionDetailDrawerProps = {
  handleClose: () => void;
  refreshList: () => void;
  selectedQuestion?: QuestionInfoType;
};

const QuestionDetailDrawer = ({
  handleClose,
  refreshList,
  selectedQuestion,
}: QuestionDetailDrawerProps) => {
  const {
    control,
    handleSubmit,
    errors,
    loading,
    onSubmit,
    register,
    selectedTab,
    setSelectedTab,
  } = useQuestionDetailDrawer(selectedQuestion, refreshList, handleClose);

  const renderView = () => {
    if (selectedTab === 'form') {
      return (
        <QuestionForm
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
          control={control}
          register={register}
          errors={errors}
        />
      );
    }
    return <PreviewSecion control={control} />;
  };
  return (
    <Drawer
      anchor='right'
      open
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '50%',
        },
      }}
    >
      <Grid container p={'30px'}>
        <Grid item xs={12}>
          <Text size='xl' textweight='bold'>
            Add Question
          </Text>
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <CustomTab
            setTabSelected={setSelectedTab}
            tabOption={QuestionTabOption}
            value={selectedTab}
          />
        </Grid>
        <Grid item xs={12}>
          {renderView()}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default QuestionDetailDrawer;
