import { useAddPdfDrawer } from '../hook';
import { PdfInfoType } from '../type';
import { Drawer, Grid } from '@mui/material';
import { Text } from '../../../common/text/TextStyle';
import CustomTab from '../../../common/component/CustomTab';
import { QuestionTabOption } from '../../dashboard/util';
import PDFForm from './PDFForm';
import PreviewPdfSection from './PreviewPdfSection';

type AddPdfDrawerProps = {
  handleClose: () => void;
  refreshList: () => void;
  selctedPdf?: PdfInfoType;
};
const AddPdfDrawer = ({ handleClose, refreshList, selctedPdf }: AddPdfDrawerProps) => {
  const {
    control,
    errors,
    handleSubmit,
    loading,
    onSubmit,
    register,
    selectedTab,
    setSelectedTab,
  } = useAddPdfDrawer(selctedPdf, refreshList, handleClose);

  const renderView = () => {
    if (selectedTab === 'form') {
      return (
        <PDFForm
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
          control={control}
          register={register}
          errors={errors}
          handleClose={handleClose}
        />
      );
    }
    return <PreviewPdfSection control={control} />;
  };
  return (
    <Drawer
      anchor='right'
      open
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '50%',
        },
      }}
    >
      <Grid container p={'30px'}>
        <Grid item xs={12}>
          <Text size='xl' textweight='bold'>
            {selctedPdf ? 'Update PDF' : 'Add PDF'}
          </Text>
        </Grid>
        <Grid item xs={12} mt={'30px'}>
          <CustomTab
            setTabSelected={setSelectedTab}
            tabOption={QuestionTabOption}
            value={selectedTab}
          />
        </Grid>
        <Grid item xs={12}>
          {renderView()}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default AddPdfDrawer;
