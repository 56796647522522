import { useState } from 'react';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import { registerInitialValue, registerValidationSchema } from '../util';
import { firebaseAuth } from '../../../firebase';
import { usePressEnterHook } from '../../../common/hook';
import { Text } from '../../../common/text/TextStyle';
import { PrimaryButton } from '../../../common/Button';
import { ColorTheme } from '../../../theme/color';
import CustomTextInput from '../../../common/field/CustomTextfield';
import { addStudent } from '../../../database/datahelper';

type StudentRegisterFormProps = {
  changeForm: (formType: string) => void;
  handleClose: () => void;
};

const StudentRegisterForm = ({ changeForm, handleClose }: StudentRegisterFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: registerInitialValue,
    resolver: yupResolver(registerValidationSchema),
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const onSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    createUserWithEmailAndPassword(firebaseAuth, values.email, values.password)
      .then(() => {
        toast.success('Register successfull! Please Login');
        addStudent(values.email).then((res) => {
          changeForm('loginForm');
          setLoading(false);
        });
      })
      .catch((err: any) => {
        toast.error(err.message || 'Something went wrong');
        setLoading(false);
      });
  };

  usePressEnterHook(handleSubmit(onSubmit));
  return (
    <Grid container spacing={'20px'}>
      <Grid item xs={12} mt={'20px'}>
        <CustomTextInput
          register={register}
          name={'email'}
          fullWidth
          label={'Email'}
          errors={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomTextInput
          fullWidth
          register={register}
          type={showPassword.password ? 'text' : 'password'}
          name={'password'}
          label={'Password'}
          errors={errors}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() =>
                    setShowPassword((prev) => ({
                      ...prev,
                      password: !prev.password,
                    }))
                  }
                >
                  {!showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextInput
          fullWidth
          register={register}
          type={showPassword.password ? 'text' : 'password'}
          name={'confirmPassword'}
          label={'Confirm Password'}
          errors={errors}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() =>
                    setShowPassword((prev) => ({
                      ...prev,
                      confirmPassword: !prev.confirmPassword,
                    }))
                  }
                >
                  {!showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {error && (
        <Grid item xs={12} textAlign={'center'}>
          <Text size='sm' textcolor={ColorTheme.system.error[400]}>
            {error}
          </Text>
        </Grid>
      )}
      <Grid item xs={12}>
        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          fullWidth
          isLoading={loading}
          title={'Register'}
        />
      </Grid>
      <Grid item xs={12}>
        <Text textweight={'regular'} textsize='14px'>
          Already have an Account?
          {/* ts-ignore */}
          <span
            style={{ fontWeight: 800, cursor: 'pointer' }}
            onClick={() => changeForm('loginForm')}
          >
            {' '}
            Login
          </span>
        </Text>
      </Grid>
    </Grid>
  );
};

export default StudentRegisterForm;
