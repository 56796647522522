import { Box, Grid, IconButton, Menu } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StudentUserData } from '../../store/userauth/type';
import { _url } from '../../util/SiteUtil';
import { Text } from '../../common/text/TextStyle';
import { ColorTheme } from '../../theme/color';
import { routeUrl } from '../route/url';

type AccountButtonProps = {
  auth: StudentUserData;
  logoutUser: () => void;
};
const AccountButton = ({ auth, logoutUser }: AccountButtonProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Box display={['none', 'none', 'block']}>
      <IconButton onClick={handleClick}>
        <img
          onMouseEnter={(e: any) => {
            e.target.src = _url('assets/account_logo_blue.svg');
          }}
          onMouseLeave={(e: any) => {
            e.target.src = _url(
              `assets/${anchorEl ? 'account_logo_blue.svg' : 'account_logo_white.svg'}`,
            );
          }}
          src={_url(`assets/${anchorEl ? 'account_logo_blue.svg' : 'account_logo_white.svg'}`)}
          alt={'asset'}
        />
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            width: '281px',
            borderRadius: 20,
          },
        }}
        id='basic-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Grid container>
          <Grid item xs={12} p={'20px 24px'}>
            <Text size={'md'} textweight={'semiBold'}>
              Hi, {auth?.userInfo?.firstName}
            </Text>
            <Text size={'sm'} textweight={'regular'}>
              {auth?.userInfo?.email}
            </Text>
          </Grid>
          <Grid
            item
            xs={12}
            p={'20px 24px'}
            borderTop={`1px ${ColorTheme.grayNeutral[200]} solid`}
            borderBottom={`1px ${ColorTheme.grayNeutral[200]} solid`}
          >
            <Text
              width={'100%'}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  color: ColorTheme.grayNeutral[200],
                },
              }}
              size={'sm'}
              textweight={'regular'}
            >
              Profile
            </Text>
            <Text
              width={'100%'}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  color: ColorTheme.grayNeutral[200],
                },
              }}
              mt={'16px'}
              size={'sm'}
              onClick={() => {
                navigate(routeUrl.askDoubtQuestionsList);
                setAnchorEl(null);
              }}
              textweight={'regular'}
            >
              My Questions
            </Text>
          </Grid>
          <Grid item xs={12} p={'20px 24px'}>
            <Text
              width={'100%'}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  color: ColorTheme.grayNeutral[200],
                },
              }}
              onClick={() => {
                logoutUser();
                setAnchorEl(null);
              }}
              size={'sm'}
              textweight={'regular'}
            >
              Sign Out
            </Text>
          </Grid>
        </Grid>
      </Menu>
    </Box>
  );
};

export default AccountButton;
