import { PdfInfoType } from '../type';
import { Control, useWatch } from 'react-hook-form';

type PreviewPdfSectionProps = {
  control: Control<PdfInfoType>;
};
const PreviewPdfSection = ({ control }: PreviewPdfSectionProps) => {
  const pdfUrl = useWatch({
    control,
    name: 'pdfUrl',
  });
  return (
    <div
      style={{
        height: '76vh',
        marginTop: 20,
      }}
    >
      <iframe src={pdfUrl} width={'100%'} height={'100%'} allow='autoplay' title='Pdf viewer' />
    </div>
  );
};

export default PreviewPdfSection;
